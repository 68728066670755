import { isNullOrEmpty } from '../../../actions/helper';
import moment from 'moment';
import { DATE_FORMAT } from './AssetDetails';

export const isAssetNameValid = (name: string | null) => {
    return !isNullOrEmpty(name);
};

export const isAssetIdentificationValid = (identification: string | null) => {
    return identification === null || identification.trim().length === 0 || isVinValid(identification.trim());
};

export const isVinValid = (identification: string) => {
    const vinRegex = new RegExp('^[1234567890ABCDEFGHJKLMNPRSTUVWXYZ]{17}$');
    return vinRegex.test(identification);
};

export const endDateIsSameOrAfterStartDateOrNull = (
    startDate: string | null | undefined,
    endDate: string | null | undefined
) => {
    if (!isNullOrEmpty(startDate) && !isNullOrEmpty(endDate)) {
        return moment(startDate, DATE_FORMAT).isSameOrBefore(moment(endDate, DATE_FORMAT));
    } else {
        return true;
    }
};
