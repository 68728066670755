import { connect } from 'react-redux';
import Assets from '../../components/assets/Assets';
import { AssetsPropertiesFromDispatch, AssetsPropertiesFromState } from './types';
import { fetchAssets, fetchDevices, updateAssetTags } from '../../actions/assets/Assets.actions';
import {
    assetTableApplyFilterChanges,
    assetTableRowSelected,
    assetTableSearchChanged,
    assetTableSortChanges,
    openEasyOnboardingDialog,
    resetAssetTagsUpdatingState,
    getAssetDetailTab,
    getAssets,
    getAssetsTableViewType,
    getAssetTableSortColumn,
    getAssetTableSortDir,
    getAssetTagLastSuccessfullyAdded,
    getAssetTagLastSuccessfullyRemoved,
    getAssetTagsLastFailedAdded,
    getAssetTagsLastFailedRemoved,
    getAssociatedDevices,
    getFetching,
    getFetchingSelectedDevice,
    getSearchString,
    getSelectedAsset,
    getShowActiveAssets,
    getShowArchivedAssets,
    getShowOnlyAssetsWithoutDataSource,
} from '../../reducers/assets/assetsSlice';
import { Asset, SortDirection } from '../../components/assets/types';
import { getUserAccount } from '../../../../configuration/login/loginSlice';
import { createAssetTags, fetchTags } from '../../actions/tags/Tags.actions';
import { getTags } from '../../reducers/tags/tagsSlice';
import { AssetsThunkDispatch } from '../../actions/assets/types';
import { injectIntl } from 'react-intl';
import { SelectedTag } from '../../components/assets/details/types';
import { withRouter } from '../../utils/routerUtils';
import { withConfigCatClient } from 'configcat-react';
import { RootState } from '../../../../configuration/setup/store';

function mapDispatchToProps(dispatch: AssetsThunkDispatch): AssetsPropertiesFromDispatch {
    return {
        fetchAssets: () => dispatch(fetchAssets()),
        fetchDevices: () => dispatch(fetchDevices()),
        selectAsset: (asset: Asset | null) => dispatch(assetTableRowSelected(asset)),
        fetchTags: (accountId: string | null) => dispatch(fetchTags(accountId)),
        createAssetTags: (assetTags: SelectedTag[], assetToUpdate?: Asset) =>
            dispatch(createAssetTags(assetTags, assetToUpdate)),
        updateAssetTags: (asset: Asset, tags: SelectedTag[]) => dispatch(updateAssetTags(asset, tags)),
        handleSortChange: (column: string, dir: SortDirection) =>
            dispatch(assetTableSortChanges({ sortBy: column, sortDir: dir })),
        handleSearchChange: (searchString: string) => dispatch(assetTableSearchChanged(searchString)),
        applyAssetFilterChanges: (filtered: boolean, showActiveAssets: boolean, showArchivedAssets: boolean) =>
            dispatch(
                assetTableApplyFilterChanges({
                    showOnlyAssetsWithoutDataSources: filtered,
                    showActiveAssets,
                    showArchivedAssets,
                })
            ),
        showCreateDialog: () => dispatch(openEasyOnboardingDialog()),
        resetAssetTagsUpdatingResetState: () => dispatch(resetAssetTagsUpdatingState()),
    };
}

function mapStateToProps(state: RootState): AssetsPropertiesFromState {
    return {
        selectedAsset: getSelectedAsset(state),
        tableViewType: getAssetsTableViewType(state),
        assets: getAssets(state),
        devices: getAssociatedDevices(state),
        fetching: getFetching(state),
        fetchingSelectedDevices: getFetchingSelectedDevice(state),
        availableTags: getTags(state),
        accountId: getUserAccount(state) || null,
        assetDetailTab: getAssetDetailTab(state),
        searchString: getSearchString(state),
        showOnlyAssetsWithoutDataSource: getShowOnlyAssetsWithoutDataSource(state),
        showActiveAssets: getShowActiveAssets(state),
        showArchivedAssets: getShowArchivedAssets(state),
        sortBy: getAssetTableSortColumn(state),
        sortDir: getAssetTableSortDir(state),
        assetTagLastSuccessfullyRemoved: getAssetTagLastSuccessfullyRemoved(state),
        assetTagLastFailedRemoved: getAssetTagsLastFailedRemoved(state),
        assetTagLastSuccessfullyAdded: getAssetTagLastSuccessfullyAdded(state),
        assetTagLastFailedAdded: getAssetTagsLastFailedAdded(state),
    };
}

const AssetsContainer = withConfigCatClient(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Assets)))
);

export default AssetsContainer;
