import { Fragment, FunctionComponent } from 'react';
import SelectionTile from './SelectionTile';

import { FormStepNavigation } from './EasyOnboardingDialog';
import { ALL_BRAND_TILES } from './configuration/brandTileConfig';
import {
    GAEventName,
    GAEventTrigger,
    GAFlowName,
    useGoogleAnalytics,
} from '../../../utils/googleAnalytics4AssetOnboardingFlow';
import { AssetTypeTile, BrandTile } from './configuration/types';

interface FormStepSelectBrandProps {
    selectedAssetType: AssetTypeTile | undefined;
    setSelectedBrand: (value: BrandTile) => void;
    handleFormStepNavigation: (goto: FormStepNavigation) => void;
}

export const FormStepSelectBrand: FunctionComponent<FormStepSelectBrandProps> = (props: FormStepSelectBrandProps) => {
    const { selectedAssetType, setSelectedBrand, handleFormStepNavigation } = props;
    const displayedBrands = selectedAssetType?.knownBrands ?? ALL_BRAND_TILES;
    const displayedBrandsCount = displayedBrands.length;
    const { dataLayerPush } = useGoogleAnalytics();

    return (
        <div
            className={`display-grid grid-cols-2 gap-20 padding-20 ${
                displayedBrandsCount <= 6 ? 'grid-cols-3-sm' : 'grid-cols-4-sm'
            }`}
        >
            {displayedBrands.map((item, index) => (
                <Fragment key={item.displayName + index}>
                    <SelectionTile
                        show="image"
                        name={item.displayName}
                        image={item.logo}
                        animationDelay={index * 0.1}
                        tileType="default"
                        onClick={() => {
                            dataLayerPush({
                                trigger: GAEventTrigger.click,
                                event: GAEventName.selectBrand,
                                element_name: item.displayName,
                                flow_name: GAFlowName.easyOnboardingFlow,
                            });
                            setSelectedBrand(item);
                            handleFormStepNavigation(FormStepNavigation.next);
                        }}
                    />
                </Fragment>
            ))}
        </div>
    );
};
