import { Asset, Masterdata } from '../../components/assets/types';
import { AssetThunkAction } from './types';
import { diff, isEmpty, isNullOrEmpty } from '../helper';
import { config } from '../../../../config';
import { trace } from '../../../../configuration/setup/trace';
import {
    assetTableRowSelected,
    assetUpdatingFailed,
    assetUpdatingFinished,
    assetUpdatingStarted,
    assetUpdatingSuccessful,
    selectedMasterdataUpdate,
} from '../../reducers/assets/assetsSlice';
import { getAccessToken } from '../../../../configuration/tokenHandling/tokenHandlingSlice';

export function updateMasterdata(
    asset: Asset,
    originalMasterdata: Masterdata | null,
    updatedMasterdata: Masterdata
): AssetThunkAction<Promise<void>> {
    return async (dispatch, getState) => {
        const state = getState();
        const masterDataUpdate =
            originalMasterdata !== null ? diff(originalMasterdata, updatedMasterdata) : updatedMasterdata;
        const updatedMasterdataRequestBody = JSON.stringify(masterDataUpdate, removeNullAndUndefinedValues);
        trace('masterDataUpdate', masterDataUpdate);
        trace('updatedMasterdataRequestBody', updatedMasterdataRequestBody);
        if (!isNullOrEmpty(updatedMasterdataRequestBody)) {
            dispatch(assetUpdatingStarted());
            const masterdataEndpoint = `${config.backend.assetAdmin}/assets/${asset.id}/masterdata`;
            const response = await fetch(masterdataEndpoint, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${getAccessToken(state)}`,
                    'Content-Type': 'application/json',
                },
                body: updatedMasterdataRequestBody,
            });
            if (response.status === 200) {
                dispatch(
                    selectedMasterdataUpdate({
                        assetId: asset.id,
                        masterdata: updatedMasterdata,
                    })
                );
                dispatch(assetTableRowSelected({ ...asset, masterdata: updatedMasterdata }));
                //
                dispatch(assetUpdatingSuccessful());
            } else {
                //
                dispatch(
                    assetUpdatingFailed({
                        errorCode: null,
                    })
                );
            }
            dispatch(assetUpdatingFinished());
        }
    };
}

const removeNullAndUndefinedValues = (key: string, value: string | object) => {
    return isEmpty(value) ? undefined : value;
};
