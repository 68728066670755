import { AssociatedDevice, Device } from '../types';
import { DeviceType } from './types';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { FormattedMessage } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/Button';
import React from 'react';
import { deviceTypeToDisplayedString } from '../TelematicsName';

export type DeviceListDeletionButtonProperties = {
    associatedDevice: AssociatedDevice;
    allAssociatedDevices: AssociatedDevice[];
    onDisassociationButtonClick: (devicesToDelete: Device[]) => void;
};

export const DeviceListDeletionButton = (props: DeviceListDeletionButtonProperties) => {
    const defaultButton = (
        <button
            className="btn btn-link btn-link-inline"
            type="button"
            id="disassociateDevice"
            data-cy="disassociate-device"
            onClick={() => props.onDisassociationButtonClick([props.associatedDevice.device])}
        >
            <span className="rioglyph rioglyph-trash" />
        </button>
    );

    if (!includesTbm3VcmPair(props.allAssociatedDevices)) {
        return defaultButton;
    }

    if (props.associatedDevice.device.type === DeviceType.VCM) {
        return (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id="tooltip" allowOnTouch data-cy="association-deletion-tooltip">
                        <FormattedMessage
                            id={'assets.assets.association.vcm.delete.tooltip.content'}
                            values={{
                                br: <br />,
                                vcm: deviceTypeToDisplayedString(DeviceType.VCM),
                                tbm3: deviceTypeToDisplayedString(DeviceType.TBM3),
                            }}
                        />
                    </Tooltip>
                }
            >
                <span className="cursor-not-allowed">
                    <Button
                        disabled
                        className="btn btn-link btn-link-inline"
                        type="button"
                        id="disassociateDevice"
                        data-cy="disassociate-device"
                    >
                        <span className="rioglyph rioglyph-trash" />
                    </Button>
                </span>
            </OverlayTrigger>
        );
    } else if (props.associatedDevice.device.type === DeviceType.TBM3) {
        const devices = props.allAssociatedDevices
            .filter((it) => [DeviceType.TBM3, DeviceType.VCM].includes(it.device.type))
            .map((it) => it.device);

        return (
            <button
                className="btn btn-link btn-link-inline"
                type="button"
                id="disassociateDevice"
                data-cy="disassociate-device"
                onClick={() => props.onDisassociationButtonClick(devices)}
            >
                <span className="rioglyph rioglyph-trash" />
            </button>
        );
    } else {
        return defaultButton;
    }
};

const includesTbm3VcmPair = (associatedDevices: AssociatedDevice[]): boolean => {
    const relevantDeviceTypes = associatedDevices
        .map((it) => it.device.type)
        .filter((type) => [DeviceType.VCM, DeviceType.TBM3].includes(type));
    return (
        relevantDeviceTypes.length === 2 &&
        relevantDeviceTypes.includes(DeviceType.VCM) &&
        relevantDeviceTypes.includes(DeviceType.TBM3)
    );
};
