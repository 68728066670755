import React from 'react';
import { DeviceType } from './details/types';
import { DeviceTypeProps } from './types';

export function deviceTypeToDisplayedString(deviceType: DeviceType) {
    switch (deviceType) {
        case DeviceType.TBM3:
            return 'RIO Box';
        case DeviceType.TRUCK_LOGIN_DEVICE:
            return 'RIO4Drivers Device';
        case DeviceType.MAN_WEBFLEET:
            return 'LINK Box';
        case DeviceType.OCU3:
            return 'MAN Telematics Box';
        case DeviceType.WEBFLEET_RETROFIT:
            return 'Webfleet RIO Edition';
        case DeviceType.KOEGEL_TELEMATICS:
            return 'Kögel Telematics';
        case DeviceType.SCHWARZMUELLER_TELEMATICS:
            return 'Schwarzmüller Telematics';
        case DeviceType.CM4:
            return 'MAN OEM Telematics';
        default:
            return toCapitalCase(deviceType);
    }
}

function toCapitalCase(str: string): string {
    return (
        str
            // Replaces hyphens with spaces
            .replace(/-/g, ' ')

            // Converts to lowercase
            .toLowerCase()

            // Capitalizes the first letter of each word
            .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
    );
}

const TelematicsName = React.memo((props: DeviceTypeProps) => <>{deviceTypeToDisplayedString(props.deviceType)}</>);

export default TelematicsName;
