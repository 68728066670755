import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { SidebarFooterProperties } from './types';
import classNames from 'classnames';
import { ActiveContractsWarning } from './ActiveContractsWarning';
import { useGetActiveContractsQuery } from '../../../../services/contractsApi';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { useEnableAssetDeleteToggle } from '../../../../../configuration/featureToggle/toggleHooks';

export const SidebarFooter = (props: SidebarFooterProperties) => {
    const {
        closeDeleteDialog,
        openDeleteDialog,
        displayDeleteDialog,
        modalDeleteButtonDisabled,
        submitButtonClick,
        saveButtonDisabled,
        deletionErrorMessageCode,
        loadingOnSave,
        deleteAsset,
        updatedAsset,
        toggleUnsavedAssetChanges,
    } = props;

    const { data: activeContracts } = useGetActiveContractsQuery(updatedAsset.id);
    const enableAssetDeleteToggle = useEnableAssetDeleteToggle();

    const submitButtonClickHandler = useCallback(() => {
        submitButtonClick();
        toggleUnsavedAssetChanges(false);
    }, [submitButtonClick, toggleUnsavedAssetChanges]);

    const buttonClassNames = classNames('btn', 'btn-primary', loadingOnSave && 'btn-loading');
    const deleteButton = (
        <div className="btn-toolbar pull-left">
            <button
                className="btn btn-danger btn-link"
                type="button"
                disabled={loadingOnSave}
                onClick={openDeleteDialog}
                data-cy="detail-view-delete-button"
            >
                <span className="rioglyph rioglyph-trash text-color-danger margin-right-5" />
                <span>
                    <FormattedMessage id={'assets.assets.asset.delete'} />
                </span>
            </button>
        </div>
    );

    const deletionFooter = (
        <>
            <div className="pull-right btn-toolbar">
                <button type="button" className="btn btn-default CancelButton" onClick={closeDeleteDialog}>
                    <FormattedMessage id={'assets.assets.asset.delete-confirmation-dialog.button.cancel'} />
                </button>
                <button
                    onClick={deleteAsset}
                    type="button"
                    className="btn btn-danger ConfirmationButton"
                    disabled={(activeContracts?.length !== 0 && !enableAssetDeleteToggle) || modalDeleteButtonDisabled}
                >
                    <FormattedMessage id={'assets.assets.asset.delete-confirmation-dialog.button.confirm'} />
                </button>
            </div>
        </>
    );

    const deletionModalContent =
        deletionErrorMessageCode == null ? (
            <div>
                <div className="text-size-16 margin-bottom-15">
                    <FormattedMessage
                        id={'assets.assets.asset.delete-confirmation-dialog.title'}
                        values={{
                            assetName: updatedAsset.name,
                            b: (chunk) => <strong>{chunk}</strong>,
                        }}
                    />
                </div>
                <div className="alert alert-danger display-flex flex-column gap-15">
                    <div className="display-flex gap-10">
                        <span className="text-color-danger text-size-h4 rioglyph rioglyph-error-sign" />
                        <div>
                            <strong className="text-size-16">
                                <FormattedMessage
                                    id={'assets.assets.asset.delete-confirmation-dialog.content.booked-services.title'}
                                />
                            </strong>
                            <div>
                                <FormattedMessage
                                    id={'assets.assets.asset.delete-confirmation-dialog.content.booked-services.hint'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="display-flex gap-10">
                        <span className="text-color-danger text-size-h4 rioglyph rioglyph-error-sign" />
                        <div>
                            <strong className="text-size-16">
                                <FormattedMessage
                                    id={'assets.assets.asset.delete-confirmation-dialog.content.deletion-note.title'}
                                />
                            </strong>
                            <div>
                                <FormattedMessage
                                    id={'assets.assets.asset.delete-confirmation-dialog.content.deletion-note.hint'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <ActiveContractsWarning assetId={updatedAsset.id} deletionMode />
            </div>
        ) : (
            <React.Fragment>
                <FormattedMessage
                    id={'assets.assets.asset.delete-confirmation-dialog.content'}
                    values={{ assetName: updatedAsset.name }}
                />
                <div className="alert alert-danger margin-top-10">
                    <FormattedMessage
                        id={`assets.assets.asset.delete-confirmation-dialog.content.error.${deletionErrorMessageCode}`}
                    />
                </div>
            </React.Fragment>
        );

    return (
        <>
            {deleteButton}
            <div className="btn-toolbar pull-right">
                <button
                    className={buttonClassNames}
                    type="button"
                    disabled={saveButtonDisabled}
                    onClick={submitButtonClickHandler}
                    data-cy="detail-view-confirm-button"
                >
                    <FormattedMessage id={'assets.button.submit'} />
                </button>
            </div>
            <Dialog
                showCloseButton
                bodyClassName=""
                bsSize="sm"
                body={deletionModalContent}
                footer={deletionFooter}
                show={displayDeleteDialog}
                useOverflow={true}
            />
        </>
    );
};
