import { useEffect, useMemo, useState } from 'react';
import AutoSuggest, { SelectedSuggestion } from '@rio-cloud/rio-uikit/AutoSuggest';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { CountryCode } from './types';
import { isNullOrEmpty } from '../../../actions/helper';
import { getCountryCodes } from './utils/countryCodeUtils';

type CountryCodeAutoSuggestProperties = CountryCodeAutoSuggestOwnProperties;

interface CountryCodeAutoSuggestOwnProperties {
    assetId: string | null;
    onSelectionChange: (countryCode: string | null) => void;
    licensePlateCountryCode: string | null;
    locale: string;
    hasError: boolean;
}

export const CountryCodeAutoSuggest = (props: CountryCodeAutoSuggestProperties) => {
    const { assetId, licensePlateCountryCode, onSelectionChange, locale, hasError } = props;

    const countryCodeEntries = useMemo(() => getCountryCodes(locale), [locale]);
    const initialDisplayValue = licensePlateCountryCode
        ? formatCountryCodeForDisplay(getCountryCodeFromCode(licensePlateCountryCode))
        : '';
    const noItemFoundMessage = '...';

    const [suggestions, setSuggestions] = useState<CountryCode[]>(countryCodeEntries);
    const [displayValue, setDisplayValue] = useState<string>(initialDisplayValue);

    useEffect(() => {
        if (isNullOrEmpty(licensePlateCountryCode)) {
            setSuggestions(countryCodeEntries);
        }
        setDisplayValue(initialDisplayValue);
    }, [countryCodeEntries, licensePlateCountryCode, initialDisplayValue, assetId]);

    function getCountryCodeFromCode(countryCode: string) {
        return countryCodeEntries.find((entry) => countryCode.toLowerCase() === entry.code.toLowerCase());
    }

    function getCountryCodesFromSearchValue(label: string | null | undefined) {
        return countryCodeEntries.filter((entry) =>
            isNullOrEmpty(label)
                ? true
                : entry.code.toLowerCase().startsWith(label!.toLowerCase()) ||
                  entry.label.toLowerCase().startsWith(label!.toLowerCase())
        );
    }

    const getSuggestionValue = (countryCode: { [name: string]: any }) => {
        onSelectionChange(countryCode.code as string);
        return formatCountryCodeForDisplay(countryCode);
    };

    const renderSuggestion = (autoSuggestSuggestion: { [name: string]: any }) => {
        return <span>{formatCountryCodeForDisplay(autoSuggestSuggestion)}</span>;
    };

    function displayValueMatchesCountryLabelAndCode(value: string) {
        return countryCodeEntries.find((entry) => value === `${entry.label} (${entry.code})`);
    }

    function displayValueMatchesCountryCode(value: string) {
        return countryCodeEntries.find((entry) => value === `${entry.code}`);
    }

    const onSuggestionsFetchRequested = (argument: { value: string }) => {
        setSuggestions(countryCodeEntries);
        if (isNullOrEmpty(argument.value)) {
            // call to handleClear internally when value empty
            onSelectionChange(null);
        } else if (displayValueMatchesCountryLabelAndCode(argument.value)) {
            // call to handleChange internally when change in value
            onSelectionChange(argument.value.split('(')[1].substring(0, 2));
        } else if (displayValueMatchesCountryCode(argument.value)) {
            onSelectionChange(argument.value);
        }
    };

    const onSuggestionSelected = (event: any, selectedSuggestion: SelectedSuggestion) => {
        const countryCode = selectedSuggestion.suggestion as any as CountryCode;
        const countryCodeFromCode = getCountryCodeFromCode(countryCode.code!);
        if (countryCodeFromCode != null) {
            onSelectionChange(countryCode.code);
        }
    };

    const handleChange = (prevLabel: any, { newValue }: { newValue: string }) => {
        setDisplayValue(newValue);
        setSuggestions(getCountryCodesFromSearchValue(newValue));
    };

    const handleClear = () => {
        setDisplayValue('');
        setSuggestions(countryCodeEntries);
    };

    const inputProperties = {
        onChange: handleChange,
        onClear: handleClear,
        placeholder: '',
        value: displayValue,
        disabled: false,
        hasError,
    };
    return (
        <div className={classNames('form-group')} data-cy={'detail-view-form-license-plate-country-code'}>
            <label>
                <FormattedMessage id={'assets.assets.asset.country-of-registration'} />
            </label>
            <AutoSuggest
                className={'form-group'}
                inputProps={{ ...inputProperties }}
                suggestions={suggestions}
                noItemMessage={noItemFoundMessage}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionSelected={onSuggestionSelected}
                renderSuggestion={renderSuggestion}
                getSuggestionValue={getSuggestionValue}
            />
        </div>
    );
};

export const formatCountryCodeForDisplay = (countryCode: CountryCode | { [name: string]: any } | undefined) => {
    return countryCode != undefined && countryCode?.label && countryCode?.code
        ? `${countryCode?.label} (${countryCode?.code})`
        : '';
};
