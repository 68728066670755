import { FilterOptionsDialogProperties } from './types';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';

enum CheckBoxValues {
    SHOW_ONLY_ASSETS_WITHOUT_DATASOURCE = 'show-only-assets-without-datasource',
    SHOW_ACTIVE_ASSETS = 'show-active-assets',
    SHOW_ARCHIVED_ASSETS = 'show-archived-assets',
}

const formattedMessagePrefix = 'assets.assets.filter-options-dialog';

export const FilterOptionsDialog = (props: FilterOptionsDialogProperties) => {
    const [showOnlyAssetsWithoutDatasourceCheckboxValue, setShowOnlyAssetsWithoutDatasourceCheckboxValue] =
        useState<boolean>(props.showOnlyAssetsWithoutDataSource);
    const [showActiveAssetsCheckboxValue, setShowActiveAssetsCheckboxValue] = useState<boolean>(props.showActiveAssets);
    const [showArchivedAssetsCheckboxValue, setShowArchivedAssetsCheckboxValue] = useState<boolean>(
        props.showArchivedAssets
    );

    const applyFilterChanges = () => {
        if (
            showOnlyAssetsWithoutDatasourceCheckboxValue !== props.showOnlyAssetsWithoutDataSource ||
            showActiveAssetsCheckboxValue !== props.showActiveAssets ||
            showArchivedAssetsCheckboxValue !== props.showArchivedAssets
        ) {
            props.applyFilterChanges(
                showOnlyAssetsWithoutDatasourceCheckboxValue,
                showActiveAssetsCheckboxValue,
                showArchivedAssetsCheckboxValue
            );
        }
        props.onClose();
    };

    const onCancel = () => {
        setShowArchivedAssetsCheckboxValue(props.showArchivedAssets);
        setShowActiveAssetsCheckboxValue(props.showActiveAssets);
        props.onClose();
    };

    const getFooter = () => {
        return (
            <div className="btn-toolbar pull-right">
                <button type={'button'} className={'btn btn-default'} onClick={onCancel} data-cy="cancel-button">
                    <FormattedMessage id={`${formattedMessagePrefix}.close`} />
                </button>
                <button
                    type={'button'}
                    className={'btn btn-primary'}
                    onClick={applyFilterChanges}
                    data-cy="apply-button"
                >
                    {<FormattedMessage id={`${formattedMessagePrefix}.apply`} />}
                </button>
            </div>
        );
    };

    const handleCheckBoxClick = (checkBoxName: CheckBoxValues) => {
        if (checkBoxName === CheckBoxValues.SHOW_ONLY_ASSETS_WITHOUT_DATASOURCE) {
            setShowOnlyAssetsWithoutDatasourceCheckboxValue(!showOnlyAssetsWithoutDatasourceCheckboxValue);
        } else if (checkBoxName === CheckBoxValues.SHOW_ACTIVE_ASSETS) {
            setShowActiveAssetsCheckboxValue(!showActiveAssetsCheckboxValue);
        } else if (checkBoxName === CheckBoxValues.SHOW_ARCHIVED_ASSETS) {
            setShowArchivedAssetsCheckboxValue(!showArchivedAssetsCheckboxValue);
        }
    };

    const getBody = () => {
        return (
            <div>
                <p className={'text-bold text-color-darkest'}>
                    <span>
                        <FormattedMessage id={`${formattedMessagePrefix}.data-sources`} />
                    </span>
                </p>
                <div className={'form-group'} data-cy={CheckBoxValues.SHOW_ONLY_ASSETS_WITHOUT_DATASOURCE}>
                    <Checkbox
                        name={CheckBoxValues.SHOW_ONLY_ASSETS_WITHOUT_DATASOURCE}
                        onClick={() => handleCheckBoxClick(CheckBoxValues.SHOW_ONLY_ASSETS_WITHOUT_DATASOURCE)}
                        checked={showOnlyAssetsWithoutDatasourceCheckboxValue}
                    >
                        <FormattedMessage
                            id={`${formattedMessagePrefix}.checkbox.${CheckBoxValues.SHOW_ONLY_ASSETS_WITHOUT_DATASOURCE}`}
                        />
                    </Checkbox>
                </div>
                <>
                    <p className={'text-bold text-color-darkest'}>
                        <span>
                            <FormattedMessage id={`${formattedMessagePrefix}.asset-status`} />
                        </span>
                    </p>
                    <div className={'form-group'} data-cy={CheckBoxValues.SHOW_ACTIVE_ASSETS}>
                        <Checkbox
                            name={CheckBoxValues.SHOW_ACTIVE_ASSETS}
                            onClick={() => handleCheckBoxClick(CheckBoxValues.SHOW_ACTIVE_ASSETS)}
                            checked={showActiveAssetsCheckboxValue}
                        >
                            <FormattedMessage
                                id={`${formattedMessagePrefix}.checkbox.${CheckBoxValues.SHOW_ACTIVE_ASSETS}`}
                            />
                        </Checkbox>
                    </div>
                    <div className={'form-group'} data-cy={CheckBoxValues.SHOW_ARCHIVED_ASSETS}>
                        <Checkbox
                            name={CheckBoxValues.SHOW_ARCHIVED_ASSETS}
                            onClick={() => handleCheckBoxClick(CheckBoxValues.SHOW_ARCHIVED_ASSETS)}
                            checked={showArchivedAssetsCheckboxValue}
                        >
                            <FormattedMessage
                                id={`${formattedMessagePrefix}.checkbox.${CheckBoxValues.SHOW_ARCHIVED_ASSETS}`}
                            />
                        </Checkbox>
                    </div>
                </>
            </div>
        );
    };

    return props.show ? (
        <Dialog
            show={props.show}
            title={<FormattedMessage id={'assets.assets.filter-options-dialog.title'} />}
            body={getBody()}
            footer={getFooter()}
            onHide={onCancel}
            bsSize={Dialog.SIZE_SM}
            showCloseButton={true}
        />
    ) : null;
};
