import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TagsState } from './types';
import { Asset, Tag } from '../../components/assets/types';
import { RootState } from '../../../../configuration/setup/store';

const initialState: TagsState = {
    tags: [],
    fetching: false,
    available: false,
};

const tagsSlice = createSlice({
    name: 'tags',
    initialState: initialState,
    reducers: {
        tagsStartLoading: (state) => {
            state.fetching = true;
            state.tags = [];
            state.available = false;
        },
        tagsLoaded: (state, action: PayloadAction<Tag[]>) => {
            state.fetching = false;
            state.tags = action.payload;
            state.available = true;
        },
        tagsLoadedFailed: (state) => {
            state.fetching = false;
            state.tags = [];
            state.available = false;
        },
        tagCreationStarted: (state) => {
            // TODO is this necessary?
            //  derived from TAG_CREATION_STARTED action, but not used anywhere in a reducer
            // state.fetching = true;
        },
        tagCreationSuccessful: (
            state,
            action: PayloadAction<{
                tag: Tag; // originally: SelectedTag
                asset: Asset | undefined;
            }>
        ) => {
            state.fetching = false;
            state.tags.push(action.payload.tag);
            state.available = true;
        },
        tagCreationFailed: (state, action: PayloadAction<{ tag: Tag }>) => {
            state.fetching = false;
            state.tags = state.tags.filter((tag) => tag.id !== action.payload.tag.id);
            state.available = true;
        },
    },
});

export const {
    tagsStartLoading,
    tagsLoaded,
    tagsLoadedFailed,
    tagCreationStarted,
    tagCreationFailed,
    tagCreationSuccessful,
} = tagsSlice.actions;

export default tagsSlice.reducer;

export const getTags = (state: RootState): Tag[] => state.tags.tags;

export const isTaggingServiceAvailable = (state: RootState): boolean => state.tags.available;
