import { FormattedMessage, FormattedPlural } from 'react-intl';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import React from 'react';
import TelematicsName from '../TelematicsName';
import { Device } from '../types';

export type DeviceListDeletionDialogProps = {
    devicesToDisassociate: Device[];
    displayDeviceDeleteDialog: boolean;
    disassociateDevices: (devices: Device[]) => () => void;
    abortDelete: () => void;
};
export const DeviceListDeletionDialog = (props: DeviceListDeletionDialogProps) => {
    const deletionModalContent = (
        <>
            <p>
                <FormattedPlural
                    value={props.devicesToDisassociate.length}
                    // zero does not make sense here, however phrase always includes `.zero` resulting in type errors
                    // if no value is given
                    zero={
                        <FormattedMessage
                            id={'assets.assets.association.delete-confirmation-dialog.content.part1.zero'}
                        />
                    }
                    one={
                        <FormattedMessage
                            id={'assets.assets.association.delete-confirmation-dialog.content.part1.one'}
                        />
                    }
                    other={
                        <FormattedMessage
                            id={'assets.assets.association.delete-confirmation-dialog.content.part1.other'}
                        />
                    }
                />
            </p>
            <ul>
                {props.devicesToDisassociate.map((it) => (
                    <li key={it.id}>
                        <b>
                            <TelematicsName deviceType={it.type} />:
                        </b>{' '}
                        {it.identification}
                    </li>
                ))}
            </ul>
            <p>
                <FormattedPlural
                    value={props.devicesToDisassociate.length}
                    // zero does not make sense here, however phrase always includes `.zero` resulting in type errors
                    // if no value is given
                    zero={
                        <FormattedMessage
                            id={'assets.assets.association.delete-confirmation-dialog.content.part2.zero'}
                        />
                    }
                    one={
                        <FormattedMessage
                            id={'assets.assets.association.delete-confirmation-dialog.content.part2.one'}
                        />
                    }
                    other={
                        <FormattedMessage
                            id={'assets.assets.association.delete-confirmation-dialog.content.part2.other'}
                        />
                    }
                />
            </p>
        </>
    );

    return props.displayDeviceDeleteDialog ? (
        <ConfirmationDialog
            show={props.displayDeviceDeleteDialog}
            title={<FormattedMessage id={'assets.assets.asset.device-delete-confirmation-dialog.title'} />}
            bsSize={'sm'}
            content={deletionModalContent}
            onClickConfirm={props.disassociateDevices(props.devicesToDisassociate)}
            onClickCancel={props.abortDelete}
            cancelButtonText={<FormattedMessage id={'assets.assets.asset.delete-confirmation-dialog.button.cancel'} />}
            confirmButtonText={
                <FormattedMessage id={'assets.assets.asset.delete-confirmation-dialog.button.confirm'} />
            }
            disableConfirm={false}
            useOverflow={true}
        />
    ) : null;
};
