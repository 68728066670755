import { AssetsThunkDispatch } from '../../actions/assets/types';
import {
    FilterOptionsDialogPropertiesFromDispatch,
    FilterOptionsDialogPropertiesFromState,
} from '../../components/assets/types';
import { connect } from 'react-redux';
import { FilterOptionsDialog } from '../../components/assets/FilterOptionsDialog';
import {
    assetTableApplyFilterChanges,
    filterOptionsDialogClose,
    getShowActiveAssets,
    getShowArchivedAssets,
    getShowFilterOptionsDialog,
    getShowOnlyAssetsWithoutDataSource,
} from '../../reducers/assets/assetsSlice';
import { withRouter } from '../../utils/routerUtils';
import { RootState } from '../../../../configuration/setup/store';

function mapDispatchToProps(dispatch: AssetsThunkDispatch): FilterOptionsDialogPropertiesFromDispatch {
    return {
        onClose: () => dispatch(filterOptionsDialogClose()),
        applyFilterChanges: (filterActive: boolean, showActiveAssets: boolean, showArchivedAssets: boolean) =>
            dispatch(
                assetTableApplyFilterChanges({
                    showOnlyAssetsWithoutDataSources: filterActive,
                    showActiveAssets,
                    showArchivedAssets,
                })
            ),
    };
}

function mapStateToProps(state: RootState): FilterOptionsDialogPropertiesFromState {
    return {
        show: getShowFilterOptionsDialog(state),
        showOnlyAssetsWithoutDataSource: getShowOnlyAssetsWithoutDataSource(state),
        showActiveAssets: getShowActiveAssets(state),
        showArchivedAssets: getShowArchivedAssets(state),
    };
}

const FilterOptionsDialogContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterOptionsDialog));

export default FilterOptionsDialogContainer;
