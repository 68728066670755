import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { config } from '../../../config';
import App from '../components/App';
import { AppPropertiesFromDispatch, AppPropertiesFromState } from './types';
import { getDisplayMessages, getLocale } from '../../../configuration/lang/langSlice';
import { RootState } from '../../../configuration/setup/store';
import { isUserSessionExpired } from '../../../configuration/login/loginSlice';
import { getSessionExpiredAcknowledged, hideSessionExpiredDialog } from '../reducers/appSlice';

const mapDispatchToProps = (dispatch: Dispatch): AppPropertiesFromDispatch => ({
    hideSessionDialog: () => dispatch(hideSessionExpiredDialog()),
});

const mapStateToProps = (state: RootState): AppPropertiesFromState => ({
    homeRoute: config.homeRoute as string,
    languageData: getDisplayMessages(state),
    showSessionExpired: isUserSessionExpired(state) && !getSessionExpiredAcknowledged(state),
    userLocale: getLocale(state),
});

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
