import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { TelematicsTile } from './configuration/types';
import { FormattedMessage, useIntl } from 'react-intl';
import RadioButton from '@rio-cloud/rio-uikit/components/radiobutton/RadioButton';

interface TelematicsListProps {
    displayedTelematics: TelematicsTile[];
    selectedTelematic: TelematicsTile | undefined;
    setSelectedTelematic: (value: TelematicsTile | undefined) => void;
}

export const TelematicsList: FunctionComponent<TelematicsListProps> = ({
    displayedTelematics,
    selectedTelematic,
    setSelectedTelematic,
}) => {
    const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);
    const [searchTerm, setSearchTerm] = useState('');
    const intl = useIntl();

    useEffect(() => {
        if (selectedTelematic) {
            setSelectedOption(selectedTelematic.displayName);
        } else {
            if (displayedTelematics.length > 0) {
                setSelectedTelematic(displayedTelematics[0]);
                setSelectedOption(displayedTelematics[0].displayName);
            }
        }
    }, [selectedTelematic, displayedTelematics, setSelectedTelematic]);

    const filteredTelematics = useMemo(() => {
        return displayedTelematics.filter(
            (tile) =>
                tile.displayName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                intl.formatMessage({ id: tile.description }).toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [displayedTelematics, searchTerm, intl]);

    const getSelectedClass = (isSelected: boolean) =>
        isSelected ? 'border-color-highlight bg-highlight-decent z-index-1' : '';

    return (
        <div className="flex-basis-100pct">
            <div className="form-group">
                <div className="input-group">
                    <span className="input-group-addon">
                        <span className="rioglyph rioglyph-search"></span>
                    </span>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Find your data source"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>
            <div className="panel panel-default max-height-350 overflow-x-hidden overflow-y-auto has-scrollbars rounded">
                {filteredTelematics.map((tile) => (
                    <RadioButton
                        key={tile.displayName}
                        name="stackedRadioBoxes"
                        custom
                        className={`padding-15 margin-top--1 border ${getSelectedClass(selectedOption === tile.displayName)}`}
                        onChange={() => {
                            setSelectedOption(tile.displayName);
                            setSelectedTelematic(tile);
                        }}
                        checked={selectedOption === tile.displayName}
                    >
                        <div className="radio-text-wrapper display-flex">
                            <span className="radio-text" />
                            <div className="margin-left-5 margin-right-5">
                                <div className="text-medium" data-testid={tile.displayName} data-cy={tile.displayName}>
                                    <FormattedMessage id={tile.displayName} />
                                </div>
                                <div className="text-color-dark">
                                    <FormattedMessage id={tile.description} />
                                </div>
                            </div>
                        </div>
                    </RadioButton>
                ))}
            </div>
        </div>
    );
};
