import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../config';
import { prepareHeaders } from './utils';

interface VinResponse {
    vin: string;
    brand: string;
}

const isVinResponse = (foo: any): foo is VinResponse => typeof foo.vin === 'string' && typeof foo.brand === 'string';

export const assetsApi = createApi({
    reducerPath: 'assetsApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.assetAdmin, prepareHeaders }),
    endpoints: (build) => ({
        getBrand: build.query<string | undefined, { vin: string }>({
            query: ({ vin }) => ({
                url: `/vins/${vin}`,
            }),
            transformResponse: (rawResult) => {
                if (isVinResponse(rawResult)) {
                    return rawResult.brand;
                } else {
                    return undefined;
                }
            },
        }),
    }),
});

export const { useLazyGetBrandQuery } = assetsApi;
