import { DropOutSurveyState } from '../../reducers/easyOnboardingSurvey/types';
import { Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import { getShowEasyOnboardingDialog } from '../../reducers/assets/assetsSlice';
import { getDropOutSurveyState, setDropOutSurveyState } from '../../reducers/easyOnboardingSurvey/easyOnboardingSlice';
import React, { ReactElement, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import BottomSheet from '@rio-cloud/rio-uikit/BottomSheet';
import Button from '@rio-cloud/rio-uikit/Button';
import { motion } from '@rio-cloud/rio-uikit/framer-motion';
import { getTenant, getUserAccount } from '../../../../configuration/login/loginSlice';
import { datadogLogs } from '@datadog/browser-logs';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RootState } from '../../../../configuration/setup/store';

interface DropOutSurveyContainerMapStateToProps {
    showEasyOnboardingDialog: boolean;
    dropOutSurveyState: DropOutSurveyState;
}

interface DropOutSurveyContainerMapDispatchToProps {
    setDropOutSurveyState: (state: DropOutSurveyState) => void;
}

const HIDE_AFTER_SUBMIT_IN_MS = 2_000;

enum SurveyResult {
    WANTED_TO_TRY_IT_OUT,
    MISCLICKED,
    DONT_KNOW_WHAT_TO_DO,
    TOO_COMPLICATED_THIRD_PARTY,
    ONBOARDING_GUIDE_NOT_ENOUGH,
    PRECONDITIONS_NOT_FULFILLED,
    OTHER,
}

const logSurveyResult = (
    dropOutSurveyState: DropOutSurveyState,
    accountId: string | null | undefined,
    tenant: string | undefined,
    result: SurveyResult,
    freeText: string | undefined
) => {
    datadogLogs.logger.info('ONBOARDING_DROPOUT_SURVEY', {
        connection_type: dropOutSurveyState.connectionType,
        rio_account_id: accountId,
        rio_tenant: tenant,
        result: SurveyResult[result],
        free_text: freeText,
    });
};

const logSurveyIsShown = (
    dropOutSurveyState: DropOutSurveyState,
    accountId: string | null | undefined,
    tenant: string | undefined
) => {
    datadogLogs.logger.info('ONBOARDING_DROPOUT_SURVEY_SHOWN', {
        connection_type: dropOutSurveyState.connectionType,
        rio_account_id: accountId,
        rio_tenant: tenant,
    });
};

const DropOutSurvey = (
    props: DropOutSurveyContainerMapStateToProps & DropOutSurveyContainerMapDispatchToProps & WrappedComponentProps
): ReactElement => {
    const { dropOutSurveyState, showEasyOnboardingDialog, intl, setDropOutSurveyState } = props;
    const showBottomSheet = dropOutSurveyState.show && !showEasyOnboardingDialog;
    const [feedbackText, setFeedbackText] = useState<string>('');
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [showTextInput, setShowInput] = useState<boolean>(false);
    const accountId = useSelector(getUserAccount);
    const tenant = useSelector(getTenant);

    useEffect(() => {
        if (showBottomSheet) {
            logSurveyIsShown(dropOutSurveyState, accountId, tenant);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showBottomSheet]);

    const onClose = (state: any) => {
        setDropOutSurveyState({ ...state, show: false });
        setShowInput(false);
        setFeedbackText('');
        setIsSubmitted(false);
    };

    const onSubmit = (result: SurveyResult, freeText: string | undefined = undefined) => {
        logSurveyResult(dropOutSurveyState, accountId, tenant, result, freeText);
        setIsSubmitted(true);
        setTimeout(() => {
            onClose(dropOutSurveyState);
        }, HIDE_AFTER_SUBMIT_IN_MS);
    };

    const onOther = () => {
        setShowInput(true);
    };

    return (
        <BottomSheet
            show={showBottomSheet}
            onClose={onClose}
            detach
            bodyClassName="padding-25"
            data-cy="drop-out-survey"
        >
            {!isSubmitted && (
                <>
                    <h4 className="text-color-dark margin-bottom-20">
                        <FormattedMessage id="assets.easyOnboardingDropOutSurvey.headline" />
                    </h4>
                    <h5 className="text-color-dark margin-bottom-20">
                        <FormattedMessage id="assets.easyOnboardingDropOutSurvey.question" />
                    </h5>
                    <div className="display-flex flex-column width-500 margin-bottom-20">
                        <Button onClick={() => onSubmit(SurveyResult.WANTED_TO_TRY_IT_OUT)} className={'margin-top-15'}>
                            <FormattedMessage id="assets.easyOnboardingDropOutSurvey.answer.tryOut" />
                        </Button>
                        <Button onClick={() => onSubmit(SurveyResult.MISCLICKED)} className={'margin-top-15'}>
                            <FormattedMessage id="assets.easyOnboardingDropOutSurvey.answer.misclicked" />
                        </Button>
                        <Button onClick={() => onSubmit(SurveyResult.DONT_KNOW_WHAT_TO_DO)} className={'margin-top-15'}>
                            <FormattedMessage id="assets.easyOnboardingDropOutSurvey.answer.doNotKnowWhatToDo" />
                        </Button>
                        <Button
                            onClick={() => onSubmit(SurveyResult.TOO_COMPLICATED_THIRD_PARTY)}
                            className={'margin-top-15'}
                        >
                            <FormattedMessage id="assets.easyOnboardingDropOutSurvey.answer.tooComplicated" />
                        </Button>
                        <Button
                            onClick={() => onSubmit(SurveyResult.ONBOARDING_GUIDE_NOT_ENOUGH)}
                            className={'margin-top-15'}
                        >
                            <FormattedMessage id="assets.easyOnboardingDropOutSurvey.answer.guideNotSufficient" />
                        </Button>
                        <Button
                            onClick={() => onSubmit(SurveyResult.PRECONDITIONS_NOT_FULFILLED)}
                            className={'margin-top-15'}
                        >
                            <FormattedMessage id="assets.easyOnboardingDropOutSurvey.answer.preconditionsNotFulfilled" />
                        </Button>
                        <Button onClick={onOther} className={'margin-top-15'}>
                            <FormattedMessage id="assets.easyOnboardingDropOutSurvey.answer.otherReason" />
                        </Button>
                        {showTextInput && (
                            <div className={'margin-top-15'}>
                                <textarea
                                    name="mapFeedbackText"
                                    className="form-control"
                                    cols={3}
                                    placeholder={intl.formatMessage({
                                        id: 'assets.easyOnboardingDropOutSurvey.answer.textInputPlaceholder',
                                    })}
                                    data-cy="feedback-text"
                                    onChange={(e) => setFeedbackText(e.target.value)}
                                />
                            </div>
                        )}
                        {showTextInput && (
                            <div className={'margin-top-15'}>
                                <Button
                                    bsStyle={Button.PRIMARY}
                                    disabled={feedbackText === ''}
                                    onClick={() => onSubmit(SurveyResult.OTHER, feedbackText)}
                                >
                                    <FormattedMessage id="assets.easyOnboardingDropOutSurvey.answer.submitButton" />
                                </Button>
                            </div>
                        )}
                    </div>
                </>
            )}
            {isSubmitted && (
                <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    className="display-flex gap-10 align-items-center"
                >
                    <div className="text-color-success text-size-20">
                        <span className="rioglyph rioglyph-ok-sign" />
                    </div>
                    <div className="text-medium text-color-darker text-size-16">
                        <FormattedMessage id="assets.easyOnboardingDropOutSurvey.afterSubmitText" />
                    </div>
                </motion.div>
            )}
        </BottomSheet>
    );
};

const mapStateToProps = (state: RootState): DropOutSurveyContainerMapStateToProps => ({
    showEasyOnboardingDialog: getShowEasyOnboardingDialog(state),
    dropOutSurveyState: getDropOutSurveyState(state),
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<Dispatch, RootState, any>
): DropOutSurveyContainerMapDispatchToProps => ({
    setDropOutSurveyState: (state): void => {
        dispatch(setDropOutSurveyState(state));
    },
});

export const DropOutSurveyContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(DropOutSurvey));
