import { AssetAssociationWithDevice } from '../../components/assets/details/types';
import { AssociatedDevice } from '../../components/assets/types';
import { pushToObjectMapArray } from '../reducerHelper';

export const getAssetAssociatedDeviceMap = (
    assetAssociationWithDevice: AssetAssociationWithDevice[]
): {
    [key: string]: AssociatedDevice[];
} => {
    const deviceMap: { [key: string]: AssociatedDevice[] } = {};
    assetAssociationWithDevice.forEach((association) => {
        if (association._embedded.device !== undefined) {
            pushToObjectMapArray(deviceMap, association.asset_id, {
                device: association['_embedded'].device,
                associationId: association.id,
            });
        }
    });
    return deviceMap;
};
