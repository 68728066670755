export function pushToObjectMapArray<T>(objectMap: { [p: string]: T[] }, key: string | number, pushObject: T) {
    if (objectMap[key] !== undefined) {
        objectMap[key].push(pushObject);
    } else {
        objectMap[key] = [pushObject];
    }
}

export function execFunctionOnObjectValues(
    input: { [key: string]: string | null },
    func: (value: string | null) => string
): { [key: string]: string } {
    const returnValue: { [key: string]: string } = {};
    Object.entries(input).forEach(([key, value]) => {
        if (key) {
            returnValue[key] = func(value);
        }
    });
    return returnValue;
}
