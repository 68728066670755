import { Asset } from '../types';
import { FormattedMessage } from 'react-intl';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { ActiveContractsWarning } from './ActiveContractsWarning';
import React from 'react';

export interface ArchiveAssetConfirmationDialogProps {
    show: boolean;
    asset: Asset;
    updateAsset: () => void;
    abortArchivingAsset: () => void;
}

export const ArchiveAssetConfirmationDialog = (props: ArchiveAssetConfirmationDialogProps) => {
    const archiveAssetModalContent = (
        <div>
            <div className="text-size-16 margin-bottom-15">
                <FormattedMessage
                    id={'assets.assets.asset.archive-confirmation-dialog.confirmation-question'}
                    values={{ assetName: props.asset.name }}
                />
            </div>
            <div className="alert alert-warning display-flex flex-column gap-15">
                <div className="display-flex gap-10">
                    <span className="text-color-warning text-size-h4 rioglyph rioglyph-warning-sign" />
                    <div>
                        <strong className="text-size-16">
                            <FormattedMessage
                                id={'assets.assets.asset.archive-confirmation-dialog.content.remove-data-sources.title'}
                            />
                        </strong>
                        <div>
                            <FormattedMessage
                                id={'assets.assets.asset.archive-confirmation-dialog.content.remove-data-sources.hint'}
                            />
                        </div>
                    </div>
                </div>

                <div className="display-flex gap-10">
                    <span className="text-color-warning text-size-h4 rioglyph rioglyph-warning-sign" />
                    <div>
                        <strong className="text-size-16">
                            <FormattedMessage
                                id={'assets.assets.asset.delete-confirmation-dialog.content.booked-services.title'}
                            />
                        </strong>
                        <div>
                            <FormattedMessage
                                id={'assets.assets.asset.delete-confirmation-dialog.content.booked-services.hint'}
                            />
                        </div>
                    </div>
                </div>
                <ActiveContractsWarning assetId={props.asset.id} deletionMode={false} />
            </div>
            <div className="padding-top-20 children-last-margin-bottom-0">
                <div className="text-bold margin-bottom-5">
                    <FormattedMessage id={'assets.assets.asset.archive-confirmation-dialog.content.reason.question'} />
                </div>
                <p>
                    <FormattedMessage
                        id={'assets.assets.asset.archive-confirmation-dialog.content.reason.explanation'}
                    />
                </p>
            </div>
        </div>
    );

    return (
        <ConfirmationDialog
            show={props.show}
            bsSize={'sm'}
            content={archiveAssetModalContent}
            onClickConfirm={props.updateAsset}
            onClickCancel={props.abortArchivingAsset}
            cancelButtonText={<FormattedMessage id={'assets.assets.asset.archive-confirmation-dialog.button.cancel'} />}
            confirmButtonText={
                <FormattedMessage id={'assets.assets.asset.archive-confirmation-dialog.button.confirm'} />
            }
            disableConfirm={false}
            useOverflow={true}
        />
    );
};
