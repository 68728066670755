import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';

export function nonVanishingErrorNotification(
    message: string | ReactNode,
    errorCode: string,
    dataAttribute?: string
): any {
    return Notification.error(
        <span data-cy={dataAttribute}>
            {message}
            <hr />
            {'Error Code: ' + errorCode}
            <span className={'notification-close'}>
                <span className="rioglyph rioglyph-remove" />
            </span>
        </span>,
        '',
        1000 * 60 * 15,
        () => {}
    );
}

export function nonVanishingFormattedErrorNotification(
    messageId: string | ReactNode,
    errorCode: string,
    dataAttribute?: string
): any {
    return nonVanishingErrorNotification(<FormattedMessage id={`${messageId}`} />, errorCode);
}

export function closableSuccessNotification(message: string | ReactNode, dataAttribute?: string) {
    return Notification.success(
        <span data-cy={dataAttribute}>
            {message}
            <span className={'notification-close'}>
                <span className="rioglyph rioglyph-remove" />
            </span>
        </span>,
        '',
        1000 * 5,
        () => {}
    );
}
export function closableSuccessFormattedNotification(messageId: string | ReactNode, dataAttribute?: string) {
    return closableSuccessNotification(<FormattedMessage id={`${messageId}`} />);
}
