import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import { NavigateFunction, Params } from 'react-router';

/*
    this is a wrapper component, because react router 6 should be used with hooks
    unless we do not want to rewrite all components,
    we can just wrap the withRouter function from react router v5
    https://gist.github.com/sibelius/7d760fa16a121ec0f4cd8b143a59bee6
*/
export const withRouter = (Component: any) => {
    const Wrapper = (props: any) => {
        const location = useLocation();
        const params = useParams();
        const push = useNavigate();
        return <Component location={location} params={params} push={push} {...props} />;
    };

    return Wrapper;
};

export interface RouteComponentProps {
    location: Location;
    params: Readonly<Params>;
    push: NavigateFunction;
}
