import { Fragment, FunctionComponent, MouseEvent } from 'react';
import SelectionTile from './SelectionTile';
import { ALL_ASSET_TYPE_TILES } from './configuration/assetTypeTileConfig';
import { FormStepNavigation } from './EasyOnboardingDialog';
import {
    GAEventName,
    GAEventTrigger,
    GAFlowName,
    useGoogleAnalytics,
} from '../../../utils/googleAnalytics4AssetOnboardingFlow';
import { AssetTypeTile } from './configuration/types';

interface FormStepSelectAssetTypeProps {
    setSelectedAssetType: (value: AssetTypeTile) => void;
    handleFormStepNavigation: (goto: FormStepNavigation, e?: MouseEvent) => void;
}

export const FormStepSelectAssetType: FunctionComponent<FormStepSelectAssetTypeProps> = (
    props: FormStepSelectAssetTypeProps
) => {
    const { setSelectedAssetType, handleFormStepNavigation } = props;
    const { dataLayerPush } = useGoogleAnalytics();
    return (
        <div className="display-grid grid-cols-2 grid-cols-2-sm gap-20 padding-20">
            {ALL_ASSET_TYPE_TILES.map((item, index) => (
                <Fragment key={item.assetType + index}>
                    <SelectionTile
                        show="image"
                        name={item.assetType}
                        image={item.logo}
                        animationDelay={index * 0.1}
                        tileType="default"
                        data-cy={`selection-tile-${item.assetType}`}
                        onClick={(e) => {
                            dataLayerPush({
                                trigger: GAEventTrigger.click,
                                event: GAEventName.selectAssetType,
                                element_name: item.assetType,
                                flow_name: GAFlowName.easyOnboardingFlow,
                            });
                            setSelectedAssetType(item);
                            handleFormStepNavigation(FormStepNavigation.next, e);
                        }}
                    />
                </Fragment>
            ))}
        </div>
    );
};
