import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormInputFieldProperties, FormInputFieldType } from './types';
import classNames from 'classnames';
import CopyToClipboard from 'react-copy-to-clipboard';
import { closableSuccessNotification } from './ClickableNotifications';

export default class FormInputField extends React.Component<React.PropsWithChildren<FormInputFieldProperties>> {
    static defaultProps = {
        withCopyButton: false,
        type: FormInputFieldType.TEXT,
    };

    copiedFeedbackMessage = () => {
        closableSuccessNotification(
            <FormattedMessage id={'assets.assets.copy-feedback'}>
                {(snippet) => {
                    return <>{'"' + this.props.currentValue + '" ' + snippet}</>;
                }}
            </FormattedMessage>
        );
    };

    render() {
        const formGroupClassNames = classNames(
            'form-group',
            this.props.hasError && 'has-error',
            this.props.hasFeedback && 'has-feedback'
        );
        const lengthValidationClassNames = classNames(
            this.props.hasError && 'text-color-danger',
            !this.props.hasError && 'text-color-highlight',
            'input-group-addon'
        );

        return (
            <div className={formGroupClassNames}>
                <label>
                    <FormattedMessage id={this.props.translationId} values={this.props.messageValues} />
                </label>
                <div className="input-group">
                    {this.props.type === FormInputFieldType.TEXT && (
                        <input
                            className="form-control"
                            type="text"
                            readOnly={this.props.disabled}
                            value={this.props.currentValue ? this.props.currentValue : ''}
                            onChange={
                                this.props.onValueChange
                                    ? (it) => this.props.onValueChange!(it.currentTarget.value)
                                    : () => {}
                            }
                            maxLength={this.props.maxLength ? this.props.maxLength : -1}
                            data-cy={this.props.dataAttribute && this.props.dataAttribute}
                        />
                    )}
                    {this.props.type === FormInputFieldType.TEXTAREA && (
                        <textarea
                            className="form-control"
                            readOnly={this.props.disabled}
                            value={this.props.currentValue ? this.props.currentValue : ''}
                            onChange={
                                this.props.onValueChange
                                    ? (it) => this.props.onValueChange!(it.currentTarget.value)
                                    : () => {}
                            }
                            maxLength={this.props.maxLength ? this.props.maxLength : -1}
                            data-cy={this.props.dataAttribute && this.props.dataAttribute}
                        />
                    )}
                    {this.props.hasLengthValidation && this.props.maxLength ? (
                        <div className={lengthValidationClassNames}>
                            {this.props.currentValue === ''
                                ? ''
                                : `${this.props.currentValue.length}/${this.props.maxLength}`}
                        </div>
                    ) : (
                        <></>
                    )}
                    {this.props.withCopyButton ? (
                        <span className="input-group-btn" onClick={this.copiedFeedbackMessage}>
                            <CopyToClipboard text={this.props.currentValue}>
                                <button
                                    className={'btn btn-default ' + (this.props.currentValue ? '' : 'disabled')}
                                    type="button"
                                    data-cy={this.props.dataAttribute && `${this.props.dataAttribute}-copy-btn`}
                                >
                                    <span className="rioglyph rioglyph-duplicate" />
                                    <span>Copy</span>
                                </button>
                            </CopyToClipboard>
                        </span>
                    ) : (
                        <React.Fragment />
                    )}
                    {this.props.children}
                </div>
                {this.props.hasFeedback && this.props.feedbackMsg && (
                    <span className={'help-block'}>{this.props.feedbackMsg}</span>
                )}
            </div>
        );
    }
}
