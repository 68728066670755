import { Asset, Device, Tag } from './types';
import { execFunctionOnObjectValues } from '../../reducers/reducerHelper';
import { CountryCode } from './details/types';
import { formatCountryCodeForDisplay } from './details/CountryCodeAutoSuggest';

const listSeparator = ' ';
const forbiddenChars = [',', '"', "'"];

export function capitalizeFirstLetter(input?: string) {
    return input != undefined ? input.charAt(0).toUpperCase() + input.slice(1) : '';
}

export function removeForbiddenChars(input: string | null): string {
    if (input) {
        return forbiddenChars.reduce((acc: string, forbiddenChar: string) => replaceAll(acc, forbiddenChar, ''), input);
    } else {
        return '';
    }
}

function replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(find, 'g'), replace);
}

export function getDevicesForAsset(asset: Asset, devicesFromProps: { [key: string]: Device[] }): string {
    const devices: Device[] = devicesFromProps[asset.id];
    return devices ? devices.map((device) => device.type + ':' + device.identification).join(listSeparator) : '';
}

export function getDataForCsvLink(assets: Asset[], availableTags: Tag[], devices: { [key: string]: Device[] }) {
    return assets.map((asset) => {
        const exportValues: { [key: string]: string | null } = {
            name: asset.name,
            assetId: asset.id,
            identification: asset.identification,
            license_plate: formatLicensePlateForDisplay(asset),
            brand: asset.brand,
            type: capitalizeFirstLetter(asset.type),
            tags: availableTags
                .filter((tag) => asset.tags.includes(tag.id))
                .map((tag) => tag.name)
                .join(listSeparator),
            devices: getDevicesForAsset(asset, devices),
        };
        return execFunctionOnObjectValues(exportValues, removeForbiddenChars);
    });
}

export const formatLicensePlateForDisplay = ({
    license_plate_country_code: countryCode,
    license_plate: licensePlate,
}: Asset) => {
    const trimmedLicensePlate = licensePlate ? licensePlate.trim() : '';
    const trimmedCountryCode = countryCode ? countryCode.trim() : '';

    return trimmedCountryCode ? `${trimmedLicensePlate} (${trimmedCountryCode})`.trim() : trimmedLicensePlate;
};
