import { useEffect } from 'react';
import { DropoutSurveyEvent, EasyOnboardingBackEvent, IncomingMessageType, MessageEvent } from './types';
import { setDropOutSurveyState } from '../../reducers/easyOnboardingSurvey/easyOnboardingSlice';
import { useAppDispatch } from '../../../../configuration/setup/hooks';
import { useNavigate } from 'react-router-dom';

export const MessageHandlerContainer = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const receiveDropOutSurveyEvent = (event: DropoutSurveyEvent) => {
        const show = event.data?.payload?.showSurvey;
        const connectionType = event.data?.payload?.connectionType;
        if (show !== undefined) {
            dispatch(setDropOutSurveyState({ show, connectionType }));
        }
    };

    const receiveEasyOnboardingBackEvent = (event: EasyOnboardingBackEvent) => {
        const url = event?.data?.payload?.url;
        if (url !== undefined && url.trim().length > 0) {
            navigate(url);
        }
    };

    useEffect(() => {
        const listenerCallback = (event: MessageEvent): void => {
            switch (event?.data?.type) {
                case IncomingMessageType.DROPOUT_SURVEY_STATE:
                    receiveDropOutSurveyEvent(event);
                    break;
                case IncomingMessageType.EASY_ONBOARDING_BACK_STATE:
                    receiveEasyOnboardingBackEvent(event);
                    break;
            }
        };
        window.addEventListener('message', listenerCallback, false);
        return (): void => window.removeEventListener('message', listenerCallback, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
};
