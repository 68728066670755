export const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isEmpty = (value: string | object): boolean => {
    if (value === null) {
        return false;
    }
    if (value === undefined) {
        return true;
    }

    if (Array.isArray(value)) {
        return value.every(isEmpty);
    } else if (typeof value === 'object') {
        return Object.values(value).every(isEmpty);
    }

    return false;
};

export const isNullOrEmpty = (s: string | null | undefined) => {
    return s === undefined || s === null || s.trim().length === 0;
};

export const trimValue = (value: string | null | undefined): null | undefined | string => {
    if (value === undefined) {
        return undefined;
    }
    return value === null || value.trim().length === 0 ? null : value?.trim();
};

export const diff = (obj1: any, obj2: any) => {
    const result: any = {};
    if (Object.is(obj1, obj2)) {
        return undefined;
    }
    if (!obj2 || typeof obj2 !== 'object') {
        return obj2;
    }
    Object.keys(obj1 || {})
        .concat(Object.keys(obj2 || {}))
        .forEach((key) => {
            if (obj1 === null || (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key]))) {
                result[key] = obj2[key];
            }
            if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
                const value = diff(obj1[key], obj2[key]);
                if (value !== undefined) {
                    result[key] = value;
                }
            }
        });
    return result;
};

export const getErrorCode = async (response: Response) => {
    let errorCode = null;

    if (response.status === 400) {
        const body: { title: string; status: number; detail: string } = await response.json();

        if (body.detail) {
            const errorCodeRegex = new RegExp('^\\[(.+)]:.*$');
            const matches = errorCodeRegex.exec(body.detail);
            errorCode = matches && matches.length === 2 ? matches[1] : null;
        }
    } else if ([401, 403].includes(response.status)) {
        errorCode = 'UNAUTHORIZED';
    }
    return errorCode;
};
