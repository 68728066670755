import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DropOutSurveyState, EasyOnboardingSurveyState } from './types';
import { RootState } from '../../../../configuration/setup/store';

const INITIAL_STATE: EasyOnboardingSurveyState = {
    dropOutSurvey: {
        show: false,
        connectionType: undefined,
    },
};

const easyOnboardingSlice = createSlice({
    name: 'easyOnboarding',
    initialState: INITIAL_STATE,
    reducers: {
        setDropOutSurveyState: (state, action: PayloadAction<DropOutSurveyState>) => {
            state.dropOutSurvey = action.payload;
        },
    },
});

export const { setDropOutSurveyState } = easyOnboardingSlice.actions;

export default easyOnboardingSlice.reducer;

export const getDropOutSurveyState = (state: RootState): DropOutSurveyState => {
    return state.easyOnboardingSurvey.dropOutSurvey;
};
