import React from 'react';
import { AssetsPropertiesFromDispatch, AssetsPropertiesFromState } from '../../containers/assets/types';
import { DeviceType } from './details/types';
import { RouteComponentProps } from '../../utils/routerUtils';
import { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import { Action } from '@reduxjs/toolkit';

export interface TableColumnDetails {
    width: number;
    defaultWidth: number;
    maxWidth: number;
    label: string;
}

export enum IdentificationType {
    vin = 'vin',
}

export enum AssetType {
    bus = 'bus',
    trailer = 'trailer',
    truck = 'truck',
    van = 'van',
}

export enum AssetStatus {
    active = 'active',
    archived = 'archived',
}

export interface MasterDataPlugAndCharge {
    pc_id: string;
}

export enum FuelType {
    DIESEL = 'diesel',
    GAS = 'gas',
    ELECTRIC = 'electric',
    HYDROGEN = 'hydrogen',
}

export interface Masterdata {
    adr: string | null | undefined;
    air_freight_suitability: string | null | undefined;
    comment: string | null | undefined;
    customs_bond: string | null | undefined;
    cost_center: string | null | undefined;
    fuel_type: FuelType | null | undefined;
    number_of_axes: number | null | undefined;
    ownership: MasterDataOwnership | null | undefined;
    plug_and_charge_ids: MasterDataPlugAndCharge[] | null | undefined;
    tires: string | null | undefined;
    vehicle_model: string | null | undefined;
    engine_type: string | null | undefined;
}

export enum OwnershipType {
    FINANCING = 'financing',
    LEASING = 'leasing',
    OWNERSHIP = 'ownership',
    RENTAL = 'rental',
}

export interface MasterDataOwnership {
    contract_duration_in_months: number | null | undefined;
    contract_rate: number | null | undefined;
    ownership_end_at: string | null | undefined;
    ownership_start_at: string | null | undefined;
    reference: string | null | undefined;
    registered_first_at: string | null | undefined;
    type: OwnershipType | null | undefined;
}

export interface Asset {
    id: string;
    account_id: string;
    name: string;
    identification: string | null;
    identification_type: IdentificationType | null;
    type: AssetType;
    status: AssetStatus;
    brand: string | null;
    license_plate: string | null;
    license_plate_country_code: string | null;
    tags: string[];
    masterdata: Masterdata | null;
}

export interface AssetWithEmbed {
    id: string;
    account_id: string;
    name: string;
    identification: string | null;
    identification_type: IdentificationType | null;
    type: AssetType;
    status: AssetStatus;
    brand: string | null;
    license_plate: string | null;
    license_plate_country_code: string | null;
    _embedded?: {
        tags?: {
            items: { id: string }[];
        };
        master_data?: Masterdata | null;
    };
}

export interface Device {
    id: string;
    identification: string;
    type: DeviceType;

    [key: string]: string;
}

export interface AssociatedDevice {
    device: Device;
    associationId: string;
}

export type AssetsProperties = AssetsPropertiesFromState & AssetsPropertiesFromDispatch;

export interface AssetListProperties {
    assets: Asset[];
    devices: { [key: string]: AssociatedDevice[] };
    tableViewType: string;
    selectedAsset: Asset | null;
    handleRowClick: (e: React.MouseEvent) => void;
    tags: Tag[];
    handleSortChange: (column: string) => void;
    sortBy: string | null;
    sortDir: string | null;
    selectedAssetIds: string[];
    handleToggleAll: (shouldSelect: boolean) => void;
    showAddToGroupDialog: (show: boolean) => void;
    showRemoveFromGroupDialog: (show: boolean) => void;
    showBulkArchiveDialog: (show: boolean) => void;
}

export interface AssetsListToolbarPropertiesFromDispatch {
    handleViewTypeChange: (viewType: TableViewTogglesViewType) => void;
    openEasyOnboardingDialog: () => void;
    handleSearchChange: (value: string) => Action;
    openFilterOptions: () => void;
}

export interface AssetsListToolbarPropertiesFromState {
    searchString: string;
    showOnlyAssetsWithoutDataSource: boolean;
    showFilterOptionsDialog: boolean;
    filterOptionsDialog: React.ReactNode;
    assets: Asset[];
    isAssetsReloadImminent?: boolean;
}

export interface AssetListToolbarOwnProps {
    createDialogComponent: React.ReactNode;
    filterOptionsDialog: React.ReactNode;
    assets: Asset[];
}

export type FilterOptionsDialogProperties = FilterOptionsDialogPropertiesFromState &
    FilterOptionsDialogPropertiesFromDispatch &
    RouteComponentProps;

export interface FilterOptionsDialogPropertiesFromDispatch {
    onClose: () => void;
    applyFilterChanges: (filterActive: boolean, showActiveAssets: boolean, showArchivedAssets: boolean) => void;
}

export interface FilterOptionsDialogPropertiesFromState {
    show: boolean;
    showOnlyAssetsWithoutDataSource: boolean;
    showActiveAssets: boolean;
    showArchivedAssets: boolean;
}

export interface FormInputFieldProperties {
    translationId: string;
    messageValues?: { [key: string]: string };
    disabled: boolean;
    currentValue: string;
    hasError?: boolean;
    hasFeedback?: boolean;
    feedbackMsg?: React.ReactNode;
    onValueChange?: (e: string | null) => void;
    dataAttribute?: string;
    withCopyButton: boolean;
    hasLengthValidation?: boolean;
    maxLength?: number;
    type?: FormInputFieldType;
}

export enum FormInputFieldType {
    TEXT = 'text',
    TEXTAREA = 'textarea',
}

export interface Tag {
    id: string;
    type: string;
    name: string;
    account_id: string;
}

export interface AssetTagsProps {
    assetTags: string[];
    tags: Tag[];
}

export interface AssetDevicesProps {
    assetDevices: AssociatedDevice[];
}

// TODO: replace with ui kit import when it is exported there
export enum SortDirection {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}

export interface DeviceTypeProps {
    deviceType: DeviceType;
}
