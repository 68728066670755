import React from 'react';
import { FormattedMessage, WrappedComponentProps } from 'react-intl';
import CustomState from '@rio-cloud/rio-uikit/CustomState';
import TagManager from '@rio-cloud/rio-uikit/TagManager';
import { SelectedTag, SelectedTagStatus, TagManagerTagProp, USER_TAG_TYPE } from './types';
import { TagManagerWrapperProperties } from '../../../containers/assets/details/TagManagerWrapper.container';
import { Tag } from '../types';
import { v4 as uuidv4 } from 'uuid';
import { RouteComponentProps } from '../../../utils/routerUtils';

type TagManagerWrapperProps = TagManagerWrapperProperties & RouteComponentProps & WrappedComponentProps;

export default class TagManagerWrapper extends React.Component<TagManagerWrapperProps> {
    render() {
        const { availableTags, taggingServiceIsAvailable, customMessage } = this.props;
        const currentTags = this.getCurrentTagsFromAvailableTags(this.props);
        return (
            <div>
                {taggingServiceIsAvailable && (
                    <CustomState
                        headline={<FormattedMessage id={'assets.assets.asset.tags'} />}
                        message={
                            <div data-cy="tag-list">
                                <div className={'margin-bottom-20'}>
                                    <FormattedMessage id={customMessage} />
                                </div>
                                <TagManager
                                    tagList={currentTags
                                        .filter((tag) => tag.status !== SelectedTagStatus.removed)
                                        .map((tag) => ({ label: tag.name }))}
                                    tagSuggestions={availableTags.map((tag: Tag) => ({ label: tag.name }))}
                                    onTagListChange={this.onTagListChange}
                                    placeholder={this.getFormattedMessagePlaceholder()}
                                    customTagPlaceholder={this.getFormattedMessageCustomTagPlaceholder()}
                                    dropdownSeparatorText={this.getFormattedMessageDropdownSeparatorText()}
                                    noItemMessage={this.getFormattedMessageNoItemMessage()}
                                    useCustomTags={this.props.useCustomTags}
                                    showInput={this.props.useCustomTags}
                                />
                            </div>
                        }
                        icons={[
                            {
                                name: 'rioglyph rioglyph-van',
                                className: 'text-size-200pct',
                            },
                            {
                                name: 'rioglyph rioglyph-truck',
                                className: 'text-size-300pct',
                            },
                            {
                                name: 'rioglyph rioglyph-driver',
                                className: 'text-size-300pct',
                            },
                            {
                                name: 'rioglyph rioglyph-trailer',
                                className: 'text-size-200pct',
                            },
                        ]}
                        outerClassName={'margin-bottom-15 bg-highlight-decent'}
                        condensed={true}
                    />
                )}
            </div>
        );
    }

    getCurrentTagsFromAvailableTags = (props: TagManagerWrapperProps): SelectedTag[] => {
        return this.props.availableTags
            .filter((tag: Tag) => props.assetTags && props.assetTags.includes(tag.id))
            .map((tag: Tag) => ({
                ...tag,
                status: SelectedTagStatus.unchanged,
            }));
    };

    onTagListChange = (tagManagerTagsChanged: TagManagerTagProp[]) => {
        const currentTags = this.getCurrentTagsFromAvailableTags(this.props);
        const accountId = this.props.accountId;
        if (accountId) {
            const tagsToAdd: SelectedTag[] = tagManagerTagsChanged
                .filter((tagChanged: TagManagerTagProp) => tagChanged.toAdd === true)
                .flatMap((tagChanged: TagManagerTagProp) => {
                    const presentTag = this.props.availableTags.find((tag: Tag) => tag.name === tagChanged.label);
                    if (presentTag !== undefined) {
                        return {
                            ...presentTag,
                            status: SelectedTagStatus.added,
                        };
                    } else {
                        return {
                            id: uuidv4(),
                            type: USER_TAG_TYPE,
                            name: tagChanged.label!,
                            account_id: accountId,
                            status: SelectedTagStatus.created,
                        };
                    }
                });

            const deleteTags: SelectedTag[] = tagManagerTagsChanged
                .filter((tagChanged: TagManagerTagProp) => tagChanged.toRemove === true)
                .flatMap((tagChanged: TagManagerTagProp) => {
                    const presentTag = currentTags.find((tag: Tag) => tag.name === tagChanged.label);
                    if (presentTag !== undefined) {
                        return {
                            ...presentTag,
                            status: SelectedTagStatus.removed,
                        };
                    } else {
                        return [];
                    }
                });
            const previousCurrentTags = currentTags.filter(
                (currentTag) => ![...tagsToAdd, ...deleteTags].map((tag) => tag.name).includes(currentTag.name)
            );
            this.props.onTagListChange([...tagsToAdd, ...deleteTags, ...previousCurrentTags]);
        }
    };

    getFormattedMessagePlaceholder(): string {
        return this.props.intl.formatMessage({ id: 'assets.assets.asset-list-detail.tag.placeholder' });
    }

    getFormattedMessageCustomTagPlaceholder(): string {
        return this.props.intl.formatMessage({ id: 'assets.assets.asset-list-detail.tag.customTagPlaceholder' });
    }

    getFormattedMessageDropdownSeparatorText(): string {
        return this.props.intl.formatMessage({ id: 'assets.assets.asset-list-detail.tag.dropdownSeparatorText' });
    }

    getFormattedMessageNoItemMessage(): string {
        return this.props.intl.formatMessage({ id: 'assets.assets.asset-list-detail.tag.noItemMessage' });
    }
}
