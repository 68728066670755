import omit from 'lodash/fp/omit';
import { UserProfile } from 'oidc-client-ts';
import { RioUserProfile } from './loginSlice';

const stripSnakeProps = (obj: UserProfile) => omit(['family_name', 'given_name'])(obj);

export const mapUserProfile = (profile: UserProfile): RioUserProfile => ({
    azp: profile.azp,
    email: profile.email,
    familyName: profile.family_name,
    givenName: profile.given_name,
    locale: profile.locale,
    name: profile.name,
    sub: profile.sub,
    ...stripSnakeProps(profile),
});
