import React from 'react';
import Tag from '@rio-cloud/rio-uikit/Tag';
import { AssetTagsProps } from './types';

export default class AssetTags extends React.Component<AssetTagsProps> {
    render() {
        return (
            <ul className="list-inline margin-bottom-0">
                {this.props.tags
                    .filter((t) => this.props.assetTags.includes(t.id))
                    .sort((t1, t2) => t1.name.toLowerCase().localeCompare(t2.name.toLowerCase()))
                    .map((t) => {
                        return (
                            <li key={t.id} className="margin-1">
                                <Tag size={'small'}> {t.name} </Tag>
                            </li>
                        );
                    })}
            </ul>
        );
    }
}
