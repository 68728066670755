import { TagsThunkAction } from './types';

import { getAccessToken } from '../../../../configuration/tokenHandling/tokenHandlingSlice';
import { config } from '../../../../config';
import { Asset, Tag } from '../../components/assets/types';
import { updateAssetTags } from '../assets/Assets.actions';
import { SelectedTag } from '../../components/assets/details/types';
import {
    tagCreationFailed,
    tagCreationStarted,
    tagCreationSuccessful,
    tagsLoaded,
    tagsLoadedFailed,
    tagsStartLoading,
} from '../../reducers/tags/tagsSlice';

export function fetchTags(accountId: string | null): TagsThunkAction<Promise<void>> {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(tagsStartLoading());
        const url = new URL(`${config.backend.tagService}/tags`);

        if (null !== accountId) {
            url.search = new URLSearchParams({ account_id: accountId }).toString();
        }

        const tagsEndpoint = `${url.toString()}`;
        const response = await fetch(tagsEndpoint, {
            headers: {
                Authorization: `Bearer ${getAccessToken(state)}`,
            },
        });
        const contentType = response.headers.get('content-type');

        if (response.status === 200 && contentType && contentType.indexOf('application/json') !== -1) {
            const json = await response.json();
            dispatch(tagsLoaded(json.items));
        } else {
            dispatch(tagsLoadedFailed());
        }
    };
}

export function createAssetTags(assetTags: SelectedTag[], assetToUpdate?: Asset): TagsThunkAction<Promise<void>> {
    return async (dispatch, getState) => {
        const state = getState();
        for (const assetTag of assetTags) {
            dispatch(tagCreationStarted());
            const url = new URL(`${config.backend.tagService}/tags/${assetTag.id}`);
            const tagsEndpoint = `${url.toString()}`;
            const tag: Tag = {
                id: assetTag.id,
                type: assetTag.type,
                name: assetTag.name,
                account_id: assetTag.account_id,
            };
            const response = await fetch(tagsEndpoint, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${getAccessToken(state)}`,
                    'Content-Type': 'application/json',
                    'If-None-Match': '*',
                },
                body: JSON.stringify(tag),
            });
            if (response.ok) {
                dispatch(
                    tagCreationSuccessful({
                        tag: assetTag,
                        asset: assetToUpdate,
                    })
                );
                if (assetToUpdate) {
                    await dispatch(updateAssetTags(assetToUpdate, [assetTag]));
                }
            } else {
                dispatch(
                    tagCreationFailed({
                        tag: assetTag,
                    })
                );
            }
        }
    };
}
