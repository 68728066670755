import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import keysIn from 'lodash/keysIn';
import mapValues from 'lodash/mapValues';
import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import SortArrows from '@rio-cloud/rio-uikit/SortArrows';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import { Asset, AssetListProperties, AssociatedDevice, TableColumnDetails, Tag } from './types';
import AssetType from './AssetType';
import AssetTags from './AssetTags';
import AssetIdentification from './AssetIdentification';
import { COLUMNS_EXCLUDED_FROM_SORTING } from './Assets';
import AssetDevices from './AssetDevices';
import { AssetStatusHint } from './AssetStatusHint';
import { formatLicensePlateForDisplay } from './CsvExport.helper';

export const AssetsList = (props: AssetListProperties) => {
    const renderSortArrows = (column: string, sortBy: string | null, sortDir: string | null) => {
        if (COLUMNS_EXCLUDED_FROM_SORTING.includes(column)) {
            return null;
        }
        return sortBy === column && sortDir ? <SortArrows direction={sortDir} /> : <SortArrows />;
    };

    const renderTableCaption = (column: string, columnDetails: TableColumnDetails) => {
        const style =
            columnDetails && columnDetails.width
                ? {
                      minWidth: columnDetails.width,
                      width: columnDetails.width,
                  }
                : {};

        return <col key={column} style={style} />;
    };

    const renderColumnValue = (column: string, asset: Asset, tags: Tag[], devices: AssociatedDevice[]) => {
        switch (column) {
            case 'name': {
                return asset.name;
            }
            case 'identification': {
                return (
                    <AssetIdentification
                        assetIdentificationType={asset.identification_type}
                        assetIdentification={asset.identification}
                    />
                );
            }
            case 'status': {
                return <AssetStatusHint assetStatus={asset.status} />;
            }
            case 'type': {
                return <AssetType assetType={asset.type} />;
            }
            case 'brand': {
                return asset.brand;
            }
            case 'license_plate': {
                return formatLicensePlateForDisplay(asset);
            }
            case 'tags': {
                return <AssetTags assetTags={asset.tags} tags={tags} />;
            }
            case 'devices': {
                return <AssetDevices assetDevices={devices === undefined ? [] : devices} />;
            }
            default:
                throw Error('column from ColumnConfig not handled in renderColumnValue - please add it');
        }
    };

    const getColumnConfig = (): { [key: string]: TableColumnDetails } => {
        return {
            status: {
                width: 0,
                defaultWidth: 0,
                maxWidth: 0,
                label: 'assets.assets.asset.status',
            },
            name: {
                width: 0,
                defaultWidth: 0,
                maxWidth: 0,
                label: 'assets.assets.asset.name',
            },
            identification: {
                width: 0,
                defaultWidth: 0,
                maxWidth: 0,
                label: 'assets.assets.asset.identification',
            },
            license_plate: {
                width: 0,
                defaultWidth: 0,
                maxWidth: 0,
                label: 'assets.assets.asset.license-plate',
            },
            brand: {
                width: 0,
                defaultWidth: 0,
                maxWidth: 0,
                label: 'assets.assets.asset.brand',
            },
            type: {
                width: 0,
                defaultWidth: 0,
                maxWidth: 0,
                label: 'assets.assets.asset.type',
            },
            tags: {
                width: 0,
                defaultWidth: 0,
                maxWidth: 0,
                label: 'assets.assets.asset.tags',
            },
            devices: {
                width: 0,
                defaultWidth: 0,
                maxWidth: 0,
                label: 'assets.assets.asset.devices',
            },
        };
    };

    const renderTableHead = (column: string, label: string) => {
        const tableHeadClassNames = classNames(
            'user-select-none ' + (COLUMNS_EXCLUDED_FROM_SORTING.includes(column) ? '' : 'sort-column')
        );

        return (
            <FormattedMessage key={column} id={label}>
                {(translation) => (
                    <th
                        key={column}
                        className={tableHeadClassNames}
                        data-field={translation}
                        data-sortby={column}
                        onClick={() => props.handleSortChange(column)}
                    >
                        <span>
                            {renderSortArrows(column, props.sortBy, props.sortDir)}
                            {translation}
                        </span>
                    </th>
                )}
            </FormattedMessage>
        );
    };

    const columnsDetails = getColumnConfig();
    const columns = keysIn(columnsDetails);
    const columnLabels = mapValues(columnsDetails, (column) => column.label);

    const rows: Asset[] = props.assets;
    const tableViewType = props.tableViewType;

    const tableClassNames = classNames(
        'table',
        'table-head-filled',
        'table-sticky',
        'table-bordered',
        'table-column-overflow-hidden',
        tableViewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD && 'table-cards table-single-card',
        tableViewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS && 'table-cards table-multi-cards'
    );

    const getRowClassNames = (assetId: string) => {
        return classNames(props.selectedAsset?.id === assetId && 'active');
    };

    const dropdownButtonItems = [
        {
            value: (
                <div data-cy="assets-table-multiselect-archive-button">
                    <span className="rioglyph rioglyph-inbox-in margin-right-10 text-size-18" />
                    <FormattedMessage id={'assets.assets.archiveSelected'} />{' '}
                    {props.selectedAssetIds.length > 0 && `(${props.selectedAssetIds.length})`}
                </div>
            ),
            disabled: props.selectedAssetIds.length === 0,
            onSelect: () => props.showBulkArchiveDialog(true),
        },
        {
            value: (
                <div data-cy="assets-table-multiselect-add-to-group-button">
                    <span className="rioglyph rioglyph-plus-sign margin-right-10 text-size-18" />
                    <FormattedMessage id={'assets.assets.addToGroup'} />{' '}
                    {props.selectedAssetIds.length > 0 && `(${props.selectedAssetIds.length})`}
                </div>
            ),
            disabled: props.selectedAssetIds.length === 0,
            onSelect: () => props.showAddToGroupDialog(true),
        },
        {
            value: (
                <div data-cy="assets-table-multiselect-remove-from-group-button">
                    <span className="rioglyph rioglyph-minus-sign margin-right-10 text-size-18" />
                    <FormattedMessage id={'assets.assets.removeFromGroup'} />{' '}
                    {props.selectedAssetIds.length > 0 && `(${props.selectedAssetIds.length})`}
                </div>
            ),
            disabled: props.selectedAssetIds.length === 0,
            onSelect: () => props.showRemoveFromGroupDialog(true),
        },
        {
            divider: true,
        },
        {
            value: (
                <div data-cy="assets-table-multiselect-select-all-button">
                    <FormattedMessage id={'assets.assets.selectAll'} />
                </div>
            ),
            onSelect: () => props.handleToggleAll(true),
        },
        {
            value: (
                <div data-cy="assets-table-multiselect-deselect-all-button">
                    <FormattedMessage id={'assets.assets.deselectAll'} />
                </div>
            ),
            onSelect: () => props.handleToggleAll(false),
        },
    ];

    const batchButton = (
        <ButtonDropdown
            id={'multi-select-dropdown'}
            title={<span className="rioglyph rioglyph rioglyph-checkboxes" />}
            className={'btn-s'}
            variant={'link'}
            iconOnly={true}
            items={dropdownButtonItems}
        />
    );

    return (
        <div id="AssetsTable">
            {rows.length ? (
                <table className={tableClassNames}>
                    <colgroup>
                        {<col className="table-checkbox" />}
                        {columns.map((column) => renderTableCaption(column, columnsDetails[column]))}
                    </colgroup>
                    <thead>
                        <tr>
                            <th className="table-checkbox" data-cy="assets-table-multiselect-button">
                                {batchButton}
                            </th>
                            {columns.map((column) => renderTableHead(column, columnLabels[column]))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row) => (
                            <tr
                                key={row.id}
                                data-key={row.id}
                                onClick={props.handleRowClick}
                                className={getRowClassNames(row.id)}
                                data-testid="assets-table-row"
                                data-cy="assets-table-row"
                            >
                                <td className="table-checkbox" data-cy="assets-table-row-check-box">
                                    <Checkbox checked={props.selectedAssetIds.includes(row.id)} />
                                </td>
                                {columns.map((col, index) => (
                                    <FormattedMessage key={index} id={columnLabels[col]}>
                                        {(label) => (
                                            <td key={col} data-field={label}>
                                                <span>
                                                    {renderColumnValue(col, row, props.tags, props.devices[row.id])}
                                                </span>
                                            </td>
                                        )}
                                    </FormattedMessage>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className={'margin-top-15pct'}>
                    <NotFoundState
                        headline={<FormattedMessage id={'assets.notfound.headline'} />}
                        message={<FormattedMessage id={'assets.notfound.message'} />}
                    />
                </div>
            )}
        </div>
    );
};
