import React, { useState } from 'react';
import TelematicsName from '../TelematicsName';
import { FormattedMessage } from 'react-intl';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { AssetStatus, AssetType, AssociatedDevice, Device } from '../types';
import { DeviceListProperties } from '../../../containers/assets/details/DeviceList.container';
import { DeviceListDeletionButton } from './DeviceListDeletionButton';
import { DeviceListDeletionDialog } from './DeviceListDeletionDialog';

export const DeviceList = (props: DeviceListProperties) => {
    const { asset, associatedDevices, fetchingDevices, deleteAssociation, openEasyOnboardingDialog } = props;
    const [displayDeviceDeleteDialog, setDisplayDeviceDeleteDialog] = useState<boolean>(false);
    const [devicesToDisassociate, setDevicesToDisassociate] = useState<Device[]>([]);

    const associateDevice = () => {
        // (selected) asset is always set when the device list tab is open
        if (asset !== null) {
            openEasyOnboardingDialog(asset.id);
        }
    };

    const abortDelete = () => {
        closeDialogAndClearDevice();
    };

    const closeDialogAndClearDevice = () => {
        setDisplayDeviceDeleteDialog(false);
        setDevicesToDisassociate([]);
    };

    const disassociateDevices = (devices: Device[]) => () => {
        for (const device of devices) {
            deleteAssociation(
                {
                    id: associatedDevices.find((associatedDevice) => associatedDevice.device.id === device.id)!
                        .associationId,
                    device_id: device.id,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                    asset_id: asset?.id!,
                },
                device.type
            );
            closeDialogAndClearDevice();
        }
    };

    const onDisassociationButtonClick = (devices: Device[]) => {
        setDisplayDeviceDeleteDialog(true);
        setDevicesToDisassociate(devices);
    };

    const deviceList =
        associatedDevices.length > 0 ? (
            <div>
                <table className="table table-condensed" data-cy="detail-view-data-sources">
                    <thead>
                        <tr>
                            <th className="padding-left-0 width-45pct">
                                <FormattedMessage id={'assets.assets.asset.devices.device.type'} />
                            </th>
                            <th className="width-45pct">
                                <FormattedMessage id={'assets.assets.asset.devices.device.identification'} />
                            </th>
                            {<th className="width-10pct" />}
                        </tr>
                    </thead>
                    <tbody>
                        {associatedDevices.map((associatedDevice: AssociatedDevice) => (
                            <tr key={associatedDevice.device.id}>
                                <td className="padding-left-0 user-select-all">
                                    <TelematicsName deviceType={associatedDevice.device.type} />
                                </td>
                                <td className="user-select-all" data-cy="device-identification">
                                    {associatedDevice.device.identification}
                                </td>
                                <td className="disassociate-device">
                                    <DeviceListDeletionButton
                                        associatedDevice={associatedDevice}
                                        allAssociatedDevices={associatedDevices}
                                        onDisassociationButtonClick={onDisassociationButtonClick}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <DeviceListDeletionDialog
                    devicesToDisassociate={devicesToDisassociate}
                    displayDeviceDeleteDialog={displayDeviceDeleteDialog}
                    disassociateDevices={disassociateDevices}
                    abortDelete={abortDelete}
                />
            </div>
        ) : (
            <div className={'padding-top-25 padding-bottom-15 text-center'}>
                {asset?.type !== AssetType.trailer ? (
                    <span>{<FormattedMessage id={'assets.assets.create.device-connect-message.details'} />}</span>
                ) : (
                    <div />
                )}
            </div>
        );

    const archivedAssetMessage = (
        <>
            <div className={'padding-top-25 padding-bottom-15 text-center'}>
                <FormattedMessage id={'assets.assets.create.device-connect-message.archived-asset'} />
            </div>
            <div className={'padding-top-25 padding-bottom-25 text-center'}>
                <FormattedMessage id={'assets.assets.create.device-connect-message.activate-asset-to-connect-device'} />
            </div>
        </>
    );

    const associateDeviceButton = (
        <div className="table-toolbar-column text-center padding-top-25 padding-bottom-15">
            <button
                className="btn btn-link btn-link-inline"
                type="button"
                id="associateDevice"
                data-cy="associate-device"
                onClick={associateDevice}
            >
                <span className="rioglyph rioglyph-plus" />
                <FormattedMessage id={'assets.assets.create.device-connect-message.connect'} />
            </button>
        </div>
    );

    const sidebarTabContent =
        asset?.status === AssetStatus.archived ? (
            archivedAssetMessage
        ) : (
            <div>
                {deviceList}
                {asset?.type !== AssetType.trailer ? associateDeviceButton : <div />}
            </div>
        );

    return (
        <div>
            {fetchingDevices ? (
                <Spinner text={<FormattedMessage id={'assets.spinner.loading'} />} />
            ) : (
                sidebarTabContent
            )}
        </div>
    );
};

DeviceList.headingText = <FormattedMessage id={'assets.assets.asset.devices'} />;
