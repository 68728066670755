import classNames from 'classnames';
import { motion } from '@rio-cloud/rio-uikit/framer-motion';
import { FunctionComponent, MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import logoCitroen from '../../../../../images/easyOnboarding/brands/typed/logo_citroen.svg';
import logoDaf from '../../../../../images/easyOnboarding/brands/typed/logo_daf.svg';
import logoMercedesBenzTruck from '../../../../../images/easyOnboarding/brands/typed/logo_mercedes_benz_truck.svg';
import logoFiat from '../../../../../images/easyOnboarding/brands/typed/logo_fiat.svg';
import logoIveco from '../../../../../images/easyOnboarding/brands/typed/logo_iveco.svg';
import logoKoegel from '../../../../../images/easyOnboarding/brands/typed/logo_koegel.svg';
import logoKrone from '../../../../../images/easyOnboarding/brands/typed/logo_krone.svg';
import logoMan from '../../../../../images/easyOnboarding/brands/typed/logo_man.svg';
import logoMercedesBenz from '../../../../../images/easyOnboarding/brands/typed/logo_mercedes_benz.svg';
import logoNissan from '../../../../../images/easyOnboarding/brands/typed/logo_nissan.svg';
import logoOpel from '../../../../../images/easyOnboarding/brands/typed/logo_opel.svg';
import logoPeugeot from '../../../../../images/easyOnboarding/brands/typed/logo_peugeot.svg';
import logoRenault from '../../../../../images/easyOnboarding/brands/typed/logo_renault.svg';
import logoRenaultTrucks from '../../../../../images/easyOnboarding/brands/typed/logo_renault_trucks.svg';
import logoScania from '../../../../../images/easyOnboarding/brands/typed/logo_scania.svg';
import logoSchmitzCargobull from '../../../../../images/easyOnboarding/brands/typed/logo_schmitz_cargobull.svg';
import logoSchwarzmueller from '../../../../../images/easyOnboarding/brands/typed/logo_schwarzmueller.svg';
import logoVolvo from '../../../../../images/easyOnboarding/brands/typed/logo_volvo.svg';
import logoVw from '../../../../../images/easyOnboarding/brands/typed/logo_vw.svg';
import logoWielton from '../../../../../images/easyOnboarding/brands/typed/logo_wielton.svg';
import assetTypeTruck from '../../../../../images/easyOnboarding/assetTypes/logo_truck.svg';
import assetTypeBus from '../../../../../images/easyOnboarding/assetTypes/logo_bus.svg';
import assetTypeVan from '../../../../../images/easyOnboarding/assetTypes/logo_van.svg';
import assetTypeTrailer from '../../../../../images/easyOnboarding/assetTypes/logo_trailer.svg';

interface SelectionTileProps {
    name: string;
    description?: string;
    image?: string;
    tileType: 'default' | 'decent';
    show: string;
    animationDelay: number;
    onClick: (e: MouseEvent) => void;
    underDevelopment?: boolean;
}

const SelectionTile: FunctionComponent<SelectionTileProps> = (props) => {
    const { show, name, description, image, tileType, animationDelay, onClick, underDevelopment } = props;

    const tileClassNames = classNames(
        'panel panel-default',
        image ? 'aspect-ratio-16-9' : 'panel-body',
        'position-relative height-100pct',
        'text-center cursor-pointer',
        'display-grid place-items-center',
        'hover-scale-105 transition-all transition-duration-02',
        tileType === 'default' ? '' : 'border-color-transparent hover-border-color-light bg-none hover-bg-white'
    );

    const tileBackground = () => {
        if (underDevelopment) {
            // striped development tile background
            return 'repeating-linear-gradient(-45deg, #fff2cc, #fff2cc 10px, white 10px, white 20px)';
        } else if (image) {
            // logos background should always be white, even on dark mode
            return '#ffffff';
        }

        return '';
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: animationDelay }}
            className={tileClassNames}
            onClick={onClick}
            style={{ background: tileBackground() }}
        >
            {show === 'text' ? (
                <div className={'display-grid gap-5'}>
                    {name && (
                        <div className="text-size-18 text-medium line-height-125rel">
                            <FormattedMessage id={name} defaultMessage={name} />
                        </div>
                    )}
                    {description && (
                        <div className="text-size-14 text-light line-height-125rel">
                            <FormattedMessage id={description} />
                        </div>
                    )}
                </div>
            ) : (
                <img className="img-responsive position-absolute inset-0" src={resolveLogo(image)} alt={name} />
            )}
        </motion.div>
    );
};

const resolveLogo = (logo: string | undefined) => {
    switch (logo) {
        // asset types
        case 'logo_truck.svg':
            return assetTypeTruck;
        case 'logo_bus.svg':
            return assetTypeBus;
        case 'logo_van.svg':
            return assetTypeVan;
        case 'logo_trailer.svg':
            return assetTypeTrailer;
        // brands
        case 'logo_citroen.svg':
            return logoCitroen;
        case 'logo_daf.svg':
            return logoDaf;
        case 'logo_mercedes_benz_truck.svg':
            return logoMercedesBenzTruck;
        case 'logo_fiat.svg':
            return logoFiat;
        case 'logo_iveco.svg':
            return logoIveco;
        case 'logo_koegel.svg':
            return logoKoegel;
        case 'logo_krone.svg':
            return logoKrone;
        case 'logo_man.svg':
            return logoMan;
        case 'logo_mercedes_benz.svg':
            return logoMercedesBenz;
        case 'logo_nissan.svg':
            return logoNissan;
        case 'logo_opel.svg':
            return logoOpel;
        case 'logo_peugeot.svg':
            return logoPeugeot;
        case 'logo_renault.svg':
            return logoRenault;
        case 'logo_renault_trucks.svg':
            return logoRenaultTrucks;
        case 'logo_scania.svg':
            return logoScania;
        case 'logo_schmitz_cargobull.svg':
            return logoSchmitzCargobull;
        case 'logo_schwarzmueller.svg':
            return logoSchwarzmueller;
        case 'logo_vw.svg':
            return logoVw;
        case 'logo_volvo.svg':
            return logoVolvo;
        case 'logo_wielton.svg':
            return logoWielton;
        default:
            return undefined;
    }
};

export default SelectionTile;
