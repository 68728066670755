// activate debug logging by adding cookie in browser:
// document.cookie="debug=true"
function debugCookieExists() {
    return document.cookie.indexOf('debug=true') >= 0;
}

export const trace =
    import.meta.env.DEV || debugCookieExists()
        ? (...args) => {
              if (import.meta.env.MODE !== 'test') {
                  console.log('[debug]', ...args);
              }
          }
        : () => {};
