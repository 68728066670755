import afLang from 'i18n-iso-countries/langs/af.json';
import amLang from 'i18n-iso-countries/langs/am.json';
import haLang from 'i18n-iso-countries/langs/ha.json';
import kuLang from 'i18n-iso-countries/langs/ku.json';
import mlLang from 'i18n-iso-countries/langs/ml.json';
import noLang from 'i18n-iso-countries/langs/no.json';
import psLang from 'i18n-iso-countries/langs/ps.json';
import sdLang from 'i18n-iso-countries/langs/sd.json';
import soLang from 'i18n-iso-countries/langs/so.json';
import sqLang from 'i18n-iso-countries/langs/sq.json';
import taLang from 'i18n-iso-countries/langs/ta.json';
import tgLang from 'i18n-iso-countries/langs/tg.json';
import ttLang from 'i18n-iso-countries/langs/tt.json';
import ugLang from 'i18n-iso-countries/langs/ug.json';
import urLang from 'i18n-iso-countries/langs/ur.json';
import viLang from 'i18n-iso-countries/langs/vi.json';
import arLang from 'i18n-iso-countries/langs/ar.json';
import azLang from 'i18n-iso-countries/langs/az.json';
import beLang from 'i18n-iso-countries/langs/be.json';
import bgLang from 'i18n-iso-countries/langs/bg.json';
import bnLang from 'i18n-iso-countries/langs/bn.json';
import bsLang from 'i18n-iso-countries/langs/bs.json';
import caLang from 'i18n-iso-countries/langs/ca.json';
import csLang from 'i18n-iso-countries/langs/cs.json';
import daLang from 'i18n-iso-countries/langs/da.json';
import deLang from 'i18n-iso-countries/langs/de.json';
import elLang from 'i18n-iso-countries/langs/el.json';
import enLang from 'i18n-iso-countries/langs/en.json';
import esLang from 'i18n-iso-countries/langs/es.json';
import etLang from 'i18n-iso-countries/langs/et.json';
import faLang from 'i18n-iso-countries/langs/fa.json';
import fiLang from 'i18n-iso-countries/langs/fi.json';
import frLang from 'i18n-iso-countries/langs/fr.json';
import glLang from 'i18n-iso-countries/langs/gl.json';
import heLang from 'i18n-iso-countries/langs/he.json';
import hiLang from 'i18n-iso-countries/langs/hi.json';
import hrLang from 'i18n-iso-countries/langs/hr.json';
import huLang from 'i18n-iso-countries/langs/hu.json';
import hyLang from 'i18n-iso-countries/langs/hy.json';
import idLang from 'i18n-iso-countries/langs/id.json';
import isLang from 'i18n-iso-countries/langs/is.json';
import itLang from 'i18n-iso-countries/langs/it.json';
import jaLang from 'i18n-iso-countries/langs/ja.json';
import kaLang from 'i18n-iso-countries/langs/ka.json';
import kkLang from 'i18n-iso-countries/langs/kk.json';
import kmLang from 'i18n-iso-countries/langs/km.json';
import koLang from 'i18n-iso-countries/langs/ko.json';
import kyLang from 'i18n-iso-countries/langs/ky.json';
import ltLang from 'i18n-iso-countries/langs/lt.json';
import lvLang from 'i18n-iso-countries/langs/lv.json';
import mkLang from 'i18n-iso-countries/langs/mk.json';
import mnLang from 'i18n-iso-countries/langs/mn.json';
import msLang from 'i18n-iso-countries/langs/ms.json';
import nbLang from 'i18n-iso-countries/langs/nb.json';
import nlLang from 'i18n-iso-countries/langs/nl.json';
import nnLang from 'i18n-iso-countries/langs/nn.json';
import plLang from 'i18n-iso-countries/langs/pl.json';
import ptLang from 'i18n-iso-countries/langs/pt.json';
import roLang from 'i18n-iso-countries/langs/ro.json';
import ruLang from 'i18n-iso-countries/langs/ru.json';
import skLang from 'i18n-iso-countries/langs/sk.json';
import slLang from 'i18n-iso-countries/langs/sl.json';
import srLang from 'i18n-iso-countries/langs/sr.json';
import svLang from 'i18n-iso-countries/langs/sv.json';
import thLang from 'i18n-iso-countries/langs/th.json';
import trLang from 'i18n-iso-countries/langs/tr.json';
import ukLang from 'i18n-iso-countries/langs/uk.json';
import uzLang from 'i18n-iso-countries/langs/uz.json';
import zhLang from 'i18n-iso-countries/langs/zh.json';
import { reportErrorToSentry } from '../../../../../../configuration/setup/sentry';
import countries from 'i18n-iso-countries';

export const getCountryCodes = (localeFromBrowser: string) => {
    const locale = localeFromBrowser.split('-')[0]?.toLocaleLowerCase();
    let lang = 'en';
    if (langCodes.includes(locale)) {
        lang = locale;
    } else {
        reportErrorToSentry(new Error(`Could not find locale ${localeFromBrowser} for CountryCodeAutoSuggest`));
    }
    let langModule: any;
    switch (lang) {
        case 'af':
            langModule = afLang;
            break;
        case 'am':
            langModule = amLang;
            break;
        case 'ha':
            langModule = haLang;
            break;
        case 'ku':
            langModule = kuLang;
            break;
        case 'ml':
            langModule = mlLang;
            break;
        case 'no':
            langModule = noLang;
            break;
        case 'ps':
            langModule = psLang;
            break;
        case 'sd':
            langModule = sdLang;
            break;
        case 'so':
            langModule = soLang;
            break;
        case 'sq':
            langModule = sqLang;
            break;
        case 'ta':
            langModule = taLang;
            break;
        case 'tg':
            langModule = tgLang;
            break;
        case 'tt':
            langModule = ttLang;
            break;
        case 'ug':
            langModule = ugLang;
            break;
        case 'ur':
            langModule = urLang;
            break;
        case 'vi':
            langModule = viLang;
            break;
        case 'ar':
            langModule = arLang;
            break;
        case 'az':
            langModule = azLang;
            break;
        case 'be':
            langModule = beLang;
            break;
        case 'bg':
            langModule = bgLang;
            break;
        case 'bn':
            langModule = bnLang;
            break;
        case 'bs':
            langModule = bsLang;
            break;
        case 'ca':
            langModule = caLang;
            break;
        case 'cs':
            langModule = csLang;
            break;
        case 'da':
            langModule = daLang;
            break;
        case 'de':
            langModule = deLang;
            break;
        case 'el':
            langModule = elLang;
            break;
        case 'en':
            langModule = enLang;
            break;
        case 'es':
            langModule = esLang;
            break;
        case 'et':
            langModule = etLang;
            break;
        case 'fa':
            langModule = faLang;
            break;
        case 'fi':
            langModule = fiLang;
            break;
        case 'fr':
            langModule = frLang;
            break;
        case 'gl':
            langModule = glLang;
            break;
        case 'he':
            langModule = heLang;
            break;
        case 'hi':
            langModule = hiLang;
            break;
        case 'hr':
            langModule = hrLang;
            break;
        case 'hu':
            langModule = huLang;
            break;
        case 'hy':
            langModule = hyLang;
            break;
        case 'id':
            langModule = idLang;
            break;
        case 'is':
            langModule = isLang;
            break;
        case 'it':
            langModule = itLang;
            break;
        case 'ja':
            langModule = jaLang;
            break;
        case 'ka':
            langModule = kaLang;
            break;
        case 'kk':
            langModule = kkLang;
            break;
        case 'km':
            langModule = kmLang;
            break;
        case 'ko':
            langModule = koLang;
            break;
        case 'ky':
            langModule = kyLang;
            break;
        case 'lt':
            langModule = ltLang;
            break;
        case 'lv':
            langModule = lvLang;
            break;
        case 'mk':
            langModule = mkLang;
            break;
        case 'mn':
            langModule = mnLang;
            break;
        case 'ms':
            langModule = msLang;
            break;
        case 'nb':
            langModule = nbLang;
            break;
        case 'nl':
            langModule = nlLang;
            break;
        case 'nn':
            langModule = nnLang;
            break;
        case 'pl':
            langModule = plLang;
            break;
        case 'pt':
            langModule = ptLang;
            break;
        case 'ro':
            langModule = roLang;
            break;
        case 'ru':
            langModule = ruLang;
            break;
        case 'sk':
            langModule = skLang;
            break;
        case 'sl':
            langModule = slLang;
            break;
        case 'sr':
            langModule = srLang;
            break;
        case 'sv':
            langModule = svLang;
            break;
        case 'th':
            langModule = thLang;
            break;
        case 'tr':
            langModule = trLang;
            break;
        case 'uk':
            langModule = ukLang;
            break;
        case 'uz':
            langModule = uzLang;
            break;
        case 'zh':
            langModule = zhLang;
            break;
    }
    countries.registerLocale(langModule);
    const names: { [key: string]: string } = countries.getNames(lang, { select: 'official' });
    return Object.entries(names).flatMap((entry) => {
        return { code: entry[0], label: entry[1] };
    });
};

const langCodes = [
    'af',
    'am',
    'ha',
    'ku',
    'ml',
    'no',
    'ps',
    'sd',
    'so',
    'sq',
    'ta',
    'tg',
    'tt',
    'ug',
    'ur',
    'vi',
    'ar',
    'az',
    'be',
    'bg',
    'bn',
    'bs',
    'ca',
    'cs',
    'da',
    'de',
    'el',
    'en',
    'es',
    'et',
    'fa',
    'fi',
    'fr',
    'gl',
    'he',
    'hi',
    'hr',
    'hu',
    'hy',
    'id',
    'is',
    'it',
    'ja',
    'ka',
    'kk',
    'km',
    'ko',
    'ky',
    'lt',
    'lv',
    'mk',
    'mn',
    'ms',
    'nb',
    'nl',
    'nn',
    'pl',
    'pt',
    'ro',
    'ru',
    'sk',
    'sl',
    'sr',
    'sv',
    'th',
    'tr',
    'uk',
    'uz',
    'zh',
];
