import React from 'react';
import { IdentificationType } from './types';
import { FormattedMessage } from 'react-intl';

interface AssetIdentificationProps {
    assetIdentificationType: IdentificationType | null;
    assetIdentification: string | null;
}

const AssetIdentification = React.memo((props: AssetIdentificationProps) => {
    return (
        <span>
            {props.assetIdentification && `${props.assetIdentification} `}
            {props.assetIdentificationType && (
                <span>
                    (
                    <FormattedMessage id={`assets.assets.asset.identification_type.${props.assetIdentificationType}`} />
                    )
                </span>
            )}
        </span>
    );
});

export default AssetIdentification;
