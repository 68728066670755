import React from 'react';
import { AssetType as AssetTypeEnum } from './types';
import { FormattedMessage } from 'react-intl';

interface AssetTypeProps {
    assetType: AssetTypeEnum;
}

const AssetType = React.memo((props: AssetTypeProps) => (
    <span>
        <span className={`rioglyph rioglyph-${props.assetType.toLowerCase()} margin-right-2`} />
        <FormattedMessage id={`assets.assets.asset.type.${props.assetType.toLowerCase()}`} />
    </span>
));

export default AssetType;
