import React from 'react';
import TableSearch from '@rio-cloud/rio-uikit/TableSearch';
import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';
import ToggleButton from '@rio-cloud/rio-uikit/ToggleButton';
import { AssetsListToolbarPropertiesFromDispatch, AssetsListToolbarPropertiesFromState } from './types';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from '../../utils/routerUtils';
import CsvExportContainer from '../../containers/assets/CsvExport.container';
import {
    GAEventName,
    GAEventTrigger,
    GAFlowName,
    useGoogleAnalytics,
} from '../../utils/googleAnalytics4AssetOnboardingFlow';

interface AssetsListToolbarState {
    searchInput: string;
}

type AssetListToolbarProperties = AssetsListToolbarPropertiesFromState &
    AssetsListToolbarPropertiesFromDispatch &
    RouteComponentProps;

export default class AssetsListToolbar extends React.Component<AssetListToolbarProperties, AssetsListToolbarState> {
    searchInputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

    constructor(props: AssetListToolbarProperties) {
        super(props);
        this.state = {
            searchInput: '',
        };
    }

    componentDidMount(): void {
        if (this.searchInputRef.current) {
            this.searchInputRef.current.focus();
        }
        this.handleSearchChange(this.props.searchString);
    }

    componentDidUpdate(
        prevProps: Readonly<AssetListToolbarProperties>,
        prevState: Readonly<AssetsListToolbarState>,
        snapshot?: any
    ): void {
        if (this.state.searchInput !== prevState.searchInput) {
            // change in internal state -> update global state
            this.props.handleSearchChange(this.state.searchInput);
        } else if (this.props.searchString !== prevProps.searchString) {
            // change in global state -> update internal state
            this.handleSearchChange(this.props.searchString);
        }
    }

    handleSearchChange = (searchValue: string) => {
        if (searchValue !== this.state.searchInput) {
            this.setState({ ...this.state, searchInput: searchValue });
        }
    };

    render() {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { dataLayerPush } = useGoogleAnalytics();

        const createButtonEasyOnboarding = (
            <div>
                <button
                    className="btn btn-primary"
                    type="button"
                    id="addAsset-new"
                    data-cy="add-asset-new"
                    onClick={() => {
                        dataLayerPush({
                            trigger: GAEventTrigger.click,
                            event: GAEventName.selectAddAsset,
                            element_name: 'add_asset_toolbar_button',
                            flow_name: GAFlowName.easyOnboardingFlow,
                        });
                        this.props.openEasyOnboardingDialog();
                    }}
                >
                    <span className="rioglyph rioglyph-plus" />
                    <FormattedMessage id={'assets.assets.create'} />
                </button>
            </div>
        );

        return (
            <TableToolbar>
                <div className="table-toolbar-container">
                    <div className="table-toolbar-group-left">
                        <div className="table-toolbar-column">{createButtonEasyOnboarding}</div>
                        {this.props.assets.length > 0 && (
                            <div className="table-toolbar-column">
                                <CsvExportContainer assets={this.props.assets} />
                            </div>
                        )}
                        {this.props.isAssetsReloadImminent && (
                            <div className="table-toolbar-column">
                                <button className="btn btn-muted btn-loading btn-icon-only" />
                            </div>
                        )}
                    </div>
                    <div className="table-toolbar-group-right">
                        <div className="table-toolbar-column justify-content-center">
                            <ToggleButton
                                active={this.props.showFilterOptionsDialog}
                                onClick={() => this.props.openFilterOptions()}
                                data-cy="options-button"
                            >
                                <span className={'rioglyph rioglyph-filter'} />
                                <FormattedMessage id={'assets.assets.options'} />
                            </ToggleButton>
                            {this.props.filterOptionsDialog}
                        </div>
                        {(this.props.assets.length > 0 || this.state.searchInput !== '') && (
                            <>
                                <div className="table-toolbar-column">
                                    <FormattedMessage id={'assets.assets.search-placeholder'}>
                                        {(placeholder) => (
                                            <TableSearch
                                                value={this.state.searchInput}
                                                inputRef={this.searchInputRef}
                                                onChange={this.handleSearchChange}
                                                placeholder={placeholder}
                                                data-cy="asset-table-search-input"
                                            />
                                        )}
                                    </FormattedMessage>
                                </div>
                                <div className="table-toolbar-column">
                                    <div className="btn-toolbar">
                                        <TableViewToggles onViewTypeChange={this.props.handleViewTypeChange} />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </TableToolbar>
        );
    }
}
