import { connect } from 'react-redux';
import { getTags } from '../../reducers/tags/tagsSlice';
import { getAssociatedDevices } from '../../reducers/assets/assetsSlice';
import { Asset, Device, Tag } from '../../components/assets/types';
import CsvExport from '../../components/assets/CsvExport';
import { injectIntl } from 'react-intl';
import { RootState } from '../../../../configuration/setup/store';

export interface CsvExportPropertiesFromState {
    assets: Asset[];
    availableTags: Tag[];
    devices: { [key: string]: Device[] };
}

export interface CsvExportOwnProps {
    assets: Asset[];
}

function mapStateToProps(state: RootState, ownProps: CsvExportOwnProps): CsvExportPropertiesFromState {
    return {
        assets: ownProps.assets,
        availableTags: getTags(state),
        devices: Object.fromEntries(
            Object.entries(getAssociatedDevices(state)).map(([assetId, associatedDevices]) => {
                return [assetId, associatedDevices.map((associatedDevice) => associatedDevice.device)];
            })
        ),
    };
}

const CsvExportContainer = connect(mapStateToProps)(injectIntl(CsvExport));

export default CsvExportContainer;
