import { connect } from 'react-redux';
import { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import AssetsListToolbar from '../../components/assets/AssetsListToolbar';
import { AssetsThunkDispatch } from '../../actions/assets/types';
import {
    AssetListToolbarOwnProps,
    AssetsListToolbarPropertiesFromDispatch,
    AssetsListToolbarPropertiesFromState,
} from '../../components/assets/types';
import {
    assetTableSearchChanged,
    assetTableViewTypeChanges,
    filterOptionsDialogOpen,
    getIsAssetsReloadImminent,
    getSearchString,
    getShowFilterOptionsDialog,
    getShowOnlyAssetsWithoutDataSource,
    openEasyOnboardingDialog,
} from '../../reducers/assets/assetsSlice';
import { withRouter } from '../../utils/routerUtils';
import { RootState } from '../../../../configuration/setup/store';

function mapDispatchToProps(dispatch: AssetsThunkDispatch): AssetsListToolbarPropertiesFromDispatch {
    return {
        handleViewTypeChange: (viewType: TableViewTogglesViewType) => dispatch(assetTableViewTypeChanges(viewType)),
        handleSearchChange: (searchString: string) => dispatch(assetTableSearchChanged(searchString)),
        openEasyOnboardingDialog: () => dispatch(openEasyOnboardingDialog()),
        openFilterOptions: () => dispatch(filterOptionsDialogOpen()),
    };
}

function mapStateToProps(state: RootState, ownProps: AssetListToolbarOwnProps): AssetsListToolbarPropertiesFromState {
    return {
        searchString: getSearchString(state),
        showOnlyAssetsWithoutDataSource: getShowOnlyAssetsWithoutDataSource(state),
        showFilterOptionsDialog: getShowFilterOptionsDialog(state),
        filterOptionsDialog: ownProps.filterOptionsDialog,
        assets: ownProps.assets,
        isAssetsReloadImminent: getIsAssetsReloadImminent(state),
    };
}

const AssetsListToolbarContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetsListToolbar));

export default AssetsListToolbarContainer;
