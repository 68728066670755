import { datadogLogs, HandlerType } from '@datadog/browser-logs';
import cloneDeepWith from 'lodash/cloneDeepWith';
import { config } from '../../config';

const tokenStringReplacer = (val: string) => {
    if (val && typeof val.replace === 'function') {
        val = val.replace(/access_token=[^&]*/, 'access_token=REDACTED');
        val = val.replace(/id_token=[^&]*/, 'id_token=REDACTED');
        return val;
    }
    return undefined;
};

if (import.meta.env.PROD) {
    datadogLogs.init({
        clientToken: config.dataDog.clientToken,
        forwardErrorsToLogs: false,
        forwardConsoleLogs: ['info'],
        site: 'datadoghq.eu',
        sessionSampleRate: 100,
        service: 'asset-administration-web',
        env: 'prod',
        beforeSend: (obj: object) => cloneDeepWith(obj, tokenStringReplacer),
    });
    datadogLogs.setGlobalContextProperty('account_id', config.dataDog.accountId);
    datadogLogs.setGlobalContextProperty('team', config.dataDog.teamId);
    datadogLogs.setGlobalContextProperty('team_identifier', config.dataDog.teamId);
} else {
    datadogLogs.init({ clientToken: 'local' });
    datadogLogs.logger.setHandler(HandlerType.console);
}
