import { AssetType } from '../../types';
import { allBrandsWithType, BRAND_TILES } from './brandTileConfig';
import { AssetTypeTile, typeBrandCombination } from './types';

export const ASSET_TYPE_TILES: Record<'truck' | 'trailer' | 'van' | 'bus', AssetTypeTile> = {
    truck: {
        assetType: AssetType.truck,
        knownBrands: [
            BRAND_TILES.daf,
            BRAND_TILES.daimler,
            BRAND_TILES.iveco,
            BRAND_TILES.man,
            BRAND_TILES.renaultTrucks,
            BRAND_TILES.scania,
            BRAND_TILES.volvo,
        ],
        logo: 'logo_truck.svg',
    },
    trailer: {
        assetType: AssetType.trailer,
        logo: 'logo_trailer.svg',
        knownBrands: [
            BRAND_TILES.koegel,
            BRAND_TILES.krone,
            BRAND_TILES.schmitzCargobull,
            BRAND_TILES.schwarzmueller,
            BRAND_TILES.wielton,
        ],
    },
    van: {
        assetType: AssetType.van,
        logo: 'logo_van.svg',
        knownBrands: [
            BRAND_TILES.citroen,
            BRAND_TILES.fiat,
            BRAND_TILES.iveco,
            BRAND_TILES.man,
            BRAND_TILES.mercedesBenz,
            BRAND_TILES.nissan,
            BRAND_TILES.opel,
            BRAND_TILES.peugeot,
            BRAND_TILES.renault,
            BRAND_TILES.volkswagen,
        ],
    },
    bus: {
        assetType: AssetType.bus,
        logo: 'logo_bus.svg',
        knownBrands: [
            BRAND_TILES.iveco,
            BRAND_TILES.man,
            BRAND_TILES.mercedesBenz,
            BRAND_TILES.scania,
            BRAND_TILES.volvo,
        ],
    },
};

export const ALL_ASSET_TYPE_TILES = Object.values(ASSET_TYPE_TILES);
export const allTypesAndBrands = (): typeBrandCombination[] =>
    ALL_ASSET_TYPE_TILES.flatMap((type: AssetTypeTile) => allBrandsWithType(type));
