import orderBy from 'lodash/orderBy';
import { ALL_TELEMATICS_TILES, TELEMATICS_TILES_DISPLAYED_FIRST } from './configuration/telematicsTileConfig';
import { FeatureToggles, useToggle } from '../../../../../configuration/featureToggle/toggleHooks';
import { FunctionComponent } from 'react';
import { useAppSelector } from '../../../../../configuration/setup/hooks';
import { getIsEuTenant } from '../../../../../configuration/login/loginSlice';
import { AssetTypeTile, BrandTile, TelematicsTile, typeBrandCombination } from './configuration/types';
import { TelematicsList } from './TelematicsList';
import { FormattedMessage } from 'react-intl';
import { PrerequisiteNotes } from './PrerequisiteNotes';
import UnderConstruction from './UnderConstruction';
import { DeviceType } from '../details/types';

//Todo find a way to adapt to selected language when new contact form is in place
const CONTACT_FORMULAR_URL = 'https://rio.cloud/de/contact/form?opener=request-for-new-telematic';

interface FormStepSelectTelematicsProps {
    selectedAssetId: string | undefined;
    selectedBrand: BrandTile | undefined;
    selectedTelematic: TelematicsTile | undefined;
    selectedAssetType: AssetTypeTile | undefined;
    setSelectedTelematic: (value: TelematicsTile | undefined) => void;
}

export const FormStepSelectTelematics: FunctionComponent<FormStepSelectTelematicsProps> = (props) => {
    const { selectedBrand, selectedAssetType, selectedTelematic, selectedAssetId, setSelectedTelematic } = props;
    const isEuTenant = useAppSelector(getIsEuTenant);
    const { value: commaSeparatedEnabledTelematicsTiles, loading: isFeatureFlagLoading } = useToggle(
        FeatureToggles.telematicstiles,
        ''
    );

    const displayedTelematics = getDisplayedTelematicsTiles(
        selectedAssetType,
        selectedBrand,
        selectedAssetId,
        isEuTenant,
        commaSeparatedEnabledTelematicsTiles,
        isFeatureFlagLoading
    );

    return (
        <div className="modal-body bg-lightest padding-0" aria-label="dialog body">
            <div>
                <div className="padding-20">
                    <div className="display-grid grid-cols-2 gap-20 ">
                        <div className="display-flex flex-column min-height-500">
                            <TelematicsList
                                displayedTelematics={displayedTelematics}
                                selectedTelematic={selectedTelematic}
                                setSelectedTelematic={setSelectedTelematic}
                            />
                            <button
                                className="btn btn-default"
                                type="button"
                                onClick={() => window.open(CONTACT_FORMULAR_URL)}
                            >
                                <div className="padding-10">
                                    <FormattedMessage id="assets.assets.create-new-dialog.missing-telematic" />
                                </div>
                                <span className="rioglyph rioglyph-support" />
                            </button>
                        </div>
                        <div className="panel panel-default panel-body display-flex flex-column">
                            {selectedTelematic ? (
                                <PrerequisiteNotes selectedTelematic={selectedTelematic} />
                            ) : (
                                <UnderConstruction />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const isFeatureToggledTileEnabled = (
    telematicsTile: TelematicsTile,
    commaSeparatedEnabledTelematicsTiles: string | number | boolean,
    isFeatureFlagLoading: boolean
) => {
    if (typeof commaSeparatedEnabledTelematicsTiles !== 'string') {
        console.warn(`unexpected type for telematic-tile-toggle: ${typeof commaSeparatedEnabledTelematicsTiles}`);
        return false;
    }

    if (isFeatureFlagLoading) {
        return false;
    } else {
        return (
            !telematicsTile.featureToggleName ||
            commaSeparatedEnabledTelematicsTiles
                .split(',')
                .map((it) => it.trim())
                .includes(telematicsTile.featureToggleName)
        );
    }
};

const getTenantSpecificTiles = (isEuTenant: boolean | undefined) => {
    return isEuTenant ? ALL_TELEMATICS_TILES : ALL_TELEMATICS_TILES.filter((it) => it.showForNonEu);
};

const getOemTiles = (
    selectedAssetType: AssetTypeTile | undefined,
    selectedBrand: BrandTile | undefined,
    isEuTenant: boolean | undefined
) => {
    const isMatch = (it: typeBrandCombination): boolean => it.type == selectedAssetType && it.brand == selectedBrand;

    const tenantSpecificTelematicTiles = getTenantSpecificTiles(isEuTenant);

    return tenantSpecificTelematicTiles.filter((telematics: TelematicsTile): boolean =>
        telematics.oemFor.some(isMatch)
    );
};

const getTelematicsTiles = (
    selectedAssetType: AssetTypeTile | undefined,
    selectedBrand: BrandTile | undefined,
    selectedAssetId: string | undefined,
    isEuTenant: boolean | undefined
) => {
    const isMatch = (it: typeBrandCombination): boolean =>
        (it.type == selectedAssetType || selectedAssetType == undefined) &&
        (it.brand == selectedBrand || selectedBrand == undefined);

    // if device is added to existing asset, it does not make sense to offer 'no telematics' device type
    const tenantSpecificTelematicTiles = getTenantSpecificTiles(isEuTenant).filter((telematicsTile) =>
        selectedAssetId !== undefined ? telematicsTile.deviceType !== DeviceType.NONE : true
    );

    return tenantSpecificTelematicTiles.filter((telematics: TelematicsTile): boolean =>
        telematics.suitableFor.some(isMatch)
    );
};

const getDisplayedTelematicsTiles = (
    selectedAssetType: AssetTypeTile | undefined,
    selectedBrand: BrandTile | undefined,
    selectedAssetId: string | undefined,
    isEuTenant: boolean | undefined,
    commaSeparatedEnabledTelematicsTiles: string | number | boolean,
    isFeatureFlagLoading: boolean
): TelematicsTile[] => {
    const eligibleTiles = getEligibleTelematicsTiles(selectedAssetType, selectedBrand, selectedAssetId, isEuTenant);

    return eligibleTiles.filter((telematicsTile) =>
        isFeatureToggledTileEnabled(telematicsTile, commaSeparatedEnabledTelematicsTiles, isFeatureFlagLoading)
    );
};

export const getEligibleTelematicsTiles = (
    selectedAssetType: AssetTypeTile | undefined,
    selectedBrand: BrandTile | undefined,
    selectedAssetId: string | undefined,
    isEuTenant: boolean | undefined
): TelematicsTile[] => {
    const oemTiles = getOemTiles(selectedAssetType, selectedBrand, isEuTenant);

    const eligibleTiles = getTelematicsTiles(selectedAssetType, selectedBrand, selectedAssetId, isEuTenant);

    const fixedEligibleTiles = TELEMATICS_TILES_DISPLAYED_FIRST.filter((tile) => eligibleTiles.includes(tile));

    const otherEligibleTiles = eligibleTiles
        .filter((tile) => !oemTiles.includes(tile))
        .filter((tile) => !TELEMATICS_TILES_DISPLAYED_FIRST.includes(tile));

    return oemTiles.concat(fixedEligibleTiles).concat(orderBy(otherEligibleTiles, 'displayName', 'asc'));
};
