import { AssetTypeTile, BrandTile, typeBrandCombination } from './types';

export const BRAND_TILES: Record<
    | 'citroen'
    | 'daf'
    | 'daimler'
    | 'fiat'
    | 'iveco'
    | 'koegel'
    | 'krone'
    | 'man'
    | 'mercedesBenz'
    | 'nissan'
    | 'opel'
    | 'peugeot'
    | 'renault'
    | 'renaultTrucks'
    | 'scania'
    | 'schmitzCargobull'
    | 'schwarzmueller'
    | 'volkswagen'
    | 'volvo'
    | 'wielton',
    BrandTile
> = {
    citroen: {
        name: 'citroen',
        logo: 'logo_citroen.svg',
        displayName: 'Citroen',
    },
    daf: {
        name: 'daf',
        logo: 'logo_daf.svg',
        displayName: 'DAF',
    },
    daimler: {
        name: 'daimler',
        logo: 'logo_mercedes_benz_truck.svg',
        displayName: 'Daimler',
    },
    fiat: {
        name: 'fiat',
        logo: 'logo_fiat.svg',
        displayName: 'Fiat',
    },
    iveco: {
        name: 'iveco',
        logo: 'logo_iveco.svg',
        displayName: 'IVECO',
    },
    koegel: {
        name: 'koegel',
        logo: 'logo_koegel.svg',
        displayName: 'Kögel',
    },
    krone: {
        name: 'krone',
        logo: 'logo_krone.svg',
        displayName: 'Krone',
    },
    man: {
        name: 'man',
        logo: 'logo_man.svg',
        displayName: 'MAN',
    },
    mercedesBenz: {
        name: 'mercedes-benz',
        logo: 'logo_mercedes_benz.svg',
        displayName: 'Mercedes-Benz',
    },
    nissan: {
        name: 'nissan',
        logo: 'logo_nissan.svg',
        displayName: 'Nissan',
    },
    opel: {
        name: 'opel',
        logo: 'logo_opel.svg',
        displayName: 'Opel',
    },
    peugeot: {
        name: 'peugeot',
        logo: 'logo_peugeot.svg',
        displayName: 'Peugeot',
    },
    renault: {
        name: 'renault',
        logo: 'logo_renault.svg',
        displayName: 'Renault',
    },
    renaultTrucks: {
        name: 'renault',
        logo: 'logo_renault_trucks.svg',
        displayName: 'Renault',
    },
    scania: {
        name: 'scania',
        logo: 'logo_scania.svg',
        displayName: 'Scania',
    },
    schmitzCargobull: {
        name: 'schmitzcargobull',
        logo: 'logo_schmitz_cargobull.svg',
        displayName: 'Schmitz Cargobull',
    },
    schwarzmueller: {
        name: 'schwarzmueller',
        logo: 'logo_schwarzmueller.svg',
        displayName: 'Schwarzmüller',
    },
    volkswagen: {
        name: 'vw',
        logo: 'logo_vw.svg',
        displayName: 'VW',
    },
    volvo: {
        name: 'volvo',
        logo: 'logo_volvo.svg',
        displayName: 'Volvo',
    },
    wielton: {
        name: 'wielton',
        logo: 'logo_wielton.svg',
        displayName: 'Wielton',
    },
};
export const ALL_BRAND_TILES = Object.values(BRAND_TILES);

export const allBrandsWithType = (type: AssetTypeTile, ...args: AssetTypeTile[]): typeBrandCombination[] =>
    [type, ...args].flatMap((type) =>
        ALL_BRAND_TILES.map((brand) => ({
            type,
            brand,
        }))
    );
