import qs, { ParsedQuery } from 'query-string';
import { NavigateFunction } from 'react-router';

export class UrlParamHelper {
    static parseQueryParams = (query: string | null): ParsedQuery => qs.parse(query ? query : '');
    private static stringifyQueryParams = (parsedQuery: ParsedQuery): string =>
        qs.stringify(parsedQuery).replace(new RegExp('sort=%2[B|0]'), 'sort=+');

    private readonly parsedQuery: ParsedQuery;
    private readonly dispatchFn: NavigateFunction;
    private readonly oldQuery: string;

    constructor(dispatchFn: NavigateFunction, queryString: string) {
        this.parsedQuery = UrlParamHelper.parseQueryParams(queryString);
        this.dispatchFn = dispatchFn;
        this.oldQuery = queryString;
    }

    replaceQueryParam(key: string, value: string | null) {
        this.parsedQuery[key] = value;
        if (null === this.parsedQuery[key] || undefined === this.parsedQuery[key] || '' === this.parsedQuery[key]) {
            // ignore 'empty' values, but not e.g. 0 - therefore !this.parsedQuery[key] is too simple
            delete this.parsedQuery[key];
        }
    }

    dispatchQueryIfChanged() {
        const newQueryString = UrlParamHelper.stringifyQueryParams(this.parsedQuery);
        const newLocation = `?${newQueryString.trim()}`;
        if (this.oldQuery !== newLocation) {
            this.dispatchFn(newLocation);
        }
    }
}

export function pushAssetsLocation(navigateFunction: NavigateFunction, locationSearch: string, row: string | null) {
    const rowString = row ? `/${row}` : '';
    navigateFunction(`/assets${rowString}${locationSearch}`, { replace: false });
}
