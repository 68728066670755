import { FormattedMessage } from 'react-intl';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import React from 'react';
import { Asset, AssetStatus } from './types';
import { BulkActiveContractsWarning } from './BulkActiveContractsWarning';
import { useAppDispatch } from '../../../../configuration/setup/hooks';
import { updateAsset } from '../../actions/assets/Assets.actions';

export interface ArchiveAssetConfirmationDialogProps {
    show: boolean;
    assets: Asset[];
    hideBulkArchiveAssetDialog: () => void;
}

export const BulkArchiveAssetConfirmationDialog = (props: ArchiveAssetConfirmationDialogProps) => {
    const dispatch = useAppDispatch();

    const archiveAssets = () => {
        props.assets.forEach((originalAsset) => {
            const updatedAsset: Asset = { ...originalAsset, status: AssetStatus.archived };
            dispatch(updateAsset(originalAsset, updatedAsset));
        });
        props.hideBulkArchiveAssetDialog();
    };

    const archiveAssetModalContent = (
        <div>
            <div className="text-size-16 margin-bottom-15">
                <FormattedMessage id={'assets.assets.asset.archive-confirmation-dialog.bulk-confirmation-question'} />
                <ul>
                    {props.assets.map((asset) => (
                        <li key={asset.id}>
                            <strong>{asset.name}</strong>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="alert alert-warning display-flex flex-column gap-15">
                <div className="display-flex gap-10">
                    <span className="text-color-warning text-size-h4 rioglyph rioglyph-warning-sign" />
                    <div>
                        <strong className="text-size-16">
                            <FormattedMessage
                                id={'assets.assets.asset.archive-confirmation-dialog.content.remove-data-sources.title'}
                            />
                        </strong>
                        <div>
                            <FormattedMessage
                                id={'assets.assets.asset.archive-confirmation-dialog.content.remove-data-sources.hint'}
                            />
                        </div>
                    </div>
                </div>

                <div className="display-flex gap-10">
                    <span className="text-color-warning text-size-h4 rioglyph rioglyph-warning-sign" />
                    <div>
                        <strong className="text-size-16">
                            <FormattedMessage
                                id={'assets.assets.asset.delete-confirmation-dialog.content.booked-services.title'}
                            />
                        </strong>
                        <div>
                            <FormattedMessage
                                id={'assets.assets.asset.delete-confirmation-dialog.content.booked-services.hint'}
                            />
                        </div>
                    </div>
                </div>
                <BulkActiveContractsWarning assets={props.assets} deletionMode={false} />
            </div>
            <div className="padding-top-20 children-last-margin-bottom-0">
                <div className="text-bold margin-bottom-5">
                    <FormattedMessage id={'assets.assets.asset.archive-confirmation-dialog.content.reason.question'} />
                </div>
                <p>
                    <FormattedMessage
                        id={'assets.assets.asset.archive-confirmation-dialog.content.reason.explanation'}
                    />
                </p>
            </div>
        </div>
    );

    return (
        <ConfirmationDialog
            show={props.show}
            bsSize={'sm'}
            content={archiveAssetModalContent}
            onClickConfirm={archiveAssets}
            onClickCancel={props.hideBulkArchiveAssetDialog}
            cancelButtonText={<FormattedMessage id={'assets.assets.asset.archive-confirmation-dialog.button.cancel'} />}
            confirmButtonText={
                <FormattedMessage id={'assets.assets.asset.archive-confirmation-dialog.button.confirm'} />
            }
            disableConfirm={false}
            useOverflow={true}
        />
    );
};
