import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { AssetsThunkDispatch } from '../../../actions/assets/types';
import { DeviceListPropertiesFromDispatch, DeviceListPropertiesFromState } from './types';
import { DeviceList } from '../../../components/assets/details/DeviceList';
import { deleteAssociation } from '../../../actions/assets/Assets.actions';
import {
    getFetchingSelectedDevice,
    getSelectedAsset,
    getSelectedAssociatedDevices,
    openEasyOnboardingDialog,
} from '../../../reducers/assets/assetsSlice';
import { RootState } from '../../../../../configuration/setup/store';

export type DeviceListProperties = DeviceListPropertiesFromState &
    DeviceListPropertiesFromDispatch &
    WrappedComponentProps;

function mapDispatchToProps(dispatch: AssetsThunkDispatch): DeviceListPropertiesFromDispatch {
    return {
        deleteAssociation: (association, deviceType) => dispatch(deleteAssociation(association, deviceType)),
        openEasyOnboardingDialog: (selectedAssetId: string) => dispatch(openEasyOnboardingDialog(selectedAssetId)),
    };
}

function mapStateToProps(state: RootState): DeviceListPropertiesFromState {
    return {
        asset: getSelectedAsset(state),
        fetchingDevices: getFetchingSelectedDevice(state),
        associatedDevices: getSelectedAssociatedDevices(state),
    };
}

const DeviceListContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(DeviceList));

export default DeviceListContainer;
