import React from 'react';
import { FormattedMessage } from 'react-intl';

export default class ReleaseInfoBannerContent extends React.Component {
    render() {
        return (
            <div>
                <FormattedMessage id={'assets.maintenance-banner'} /> &nbsp;
                {/* <span className={'rioglyph rioglyph-envelope text-white'}> &nbsp;</span> */}
                {/* <a href="mailto:feedback-assets@rio.cloud" className={'text-white text-decoration-underline text-bold'}> */}
                {/*     feedback-assets@rio.cloud */}
                {/* </a> */}
            </div>
        );
    }
}
