import { TelematicsTile } from './configuration/types';
import { FormattedMessage } from 'react-intl';
import IframeResizer from 'iframe-resizer-react';

interface PrerequisiteNotesProps {
    selectedTelematic?: TelematicsTile;
}

export const PrerequisiteNotes = ({ selectedTelematic }: PrerequisiteNotesProps) => {
    return (
        <>
            <div>
                <div className="text-size-20">
                    <FormattedMessage
                        id={selectedTelematic?.displayName}
                        defaultMessage={selectedTelematic?.displayName}
                    />
                </div>
                <div className="text-size-14">
                    <FormattedMessage id={selectedTelematic?.description} />
                </div>
                <hr className="margin-y-15" />
            </div>
            <div>
                <div className="text-size-16 margin-bottom-10">
                    <FormattedMessage
                        id={'assets.assets.create-new-dialog.telematics.tiles.prerequisites.before-you-book'}
                    />
                </div>
                {selectedTelematic && selectedTelematic.prerequisitesUri ? (
                    <IframeResizer
                        id={'prerequisites-iframe-wrapper'}
                        title={'prerequisites-iframe-wrapper'}
                        src={selectedTelematic.prerequisitesUri}
                        className={'modal-body padding-0 height-75vh'}
                        heightCalculationMethod={'lowestElement'}
                        style={{ width: '1px', minWidth: '100%', border: '0' }}
                        data-cy={'prerequisites-iframe-wrapper'}
                        data-testid={'prerequisites-iframe-wrapper'}
                        maxHeight={350}
                    />
                ) : (
                    <>
                        <ul className="icon-list display-flex flex-column gap-15">
                            <li className="margin-bottom-0 display-flex align-items-start gap-10">
                                <span className="rioglyph rioglyph-ok-sign" />
                                <div>
                                    <span>
                                        <FormattedMessage
                                            id={
                                                'assets.assets.create-new-dialog.telematics.tiles.prerequisites.requirements'
                                            }
                                        />
                                    </span>
                                    <ul className="tree-list margin-bottom-0 margin-left-15 margin-top-10">
                                        <li>
                                            <span>
                                                <FormattedMessage
                                                    id={
                                                        'assets.assets.create-new-dialog.telematics.tiles.prerequisites.requirements.item1'
                                                    }
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <FormattedMessage
                                                    id={
                                                        'assets.assets.create-new-dialog.telematics.tiles.prerequisites.requirements.item2'
                                                    }
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <FormattedMessage
                                                    id={
                                                        'assets.assets.create-new-dialog.telematics.tiles.prerequisites.requirements.item3'
                                                    }
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <FormattedMessage
                                                    id={
                                                        'assets.assets.create-new-dialog.telematics.tiles.prerequisites.requirements.item4'
                                                    }
                                                />
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </>
                )}
            </div>
        </>
    );
};
