import { TagManagerWrapperOwnProperties, TagManagerWrapperPropsFromState } from './types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isTaggingServiceAvailable } from '../../../reducers/tags/tagsSlice';
import TagManagerWrapper from '../../../components/assets/details/TagManagerWrapper';
import { withRouter } from '../../../utils/routerUtils';
import { RootState } from '../../../../../configuration/setup/store';

export type TagManagerWrapperProperties = TagManagerWrapperPropsFromState & TagManagerWrapperOwnProperties;

const mapStateToProps = (state: RootState): TagManagerWrapperPropsFromState => ({
    taggingServiceIsAvailable: isTaggingServiceAvailable(state),
});

const TagManagerWrapperContainer = withRouter(connect(mapStateToProps)(injectIntl(TagManagerWrapper)));

export default TagManagerWrapperContainer;
