export enum IncomingMessageType {
    DROPOUT_SURVEY_STATE = 'EVENT_SHOW_EASY_ONBOARDING_SURVEY',
    EASY_ONBOARDING_BACK_STATE = 'EVENT_EASY_ONBOARDING_BACK',
}

export interface MessageEvent {
    data?: {
        type?: string;
    };
}

export type DropoutSurveyEvent = MessageEvent & {
    data?: {
        payload?: {
            showSurvey?: boolean;
            connectionType?: string;
        };
    };
};
export type EasyOnboardingBackEvent = MessageEvent & {
    data?: {
        payload?: {
            url?: string;
        };
    };
};
