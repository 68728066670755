declare global {
    interface Window {
        dataLayer?: any[];
    }
}

export enum GAEventTrigger {
    click = 'click',
    visibility = 'visibility',
}

export enum GAFlowName {
    easyOnboardingFlow = 'easyOnboardingFlow',
}

export enum GAEventName {
    selectAddAsset = 'select_add_asset',
    selectAssetType = 'select_vehicle_type',
    selectBrand = 'select_brand',
    selectTelematics = 'select_telematics',
    selectTelematicsGroup = 'select_telematics_group',
}

interface UserProperties {
    accountId?: string;
    tenant?: string;
}

interface UserEvent {
    trigger: GAEventTrigger;
    event: GAEventName | string;
    element_name?: string;
    flow_name?: GAFlowName | string;
}

export const useGoogleAnalytics = () => {
    const dataLayerPush = (event: UserEvent | UserProperties) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ...event });
    };
    return { dataLayerPush };
};
