import React, { useEffect } from 'react';
import { AssetDetailsProperties, DeviceType } from './types';
import FormInputField from '../FormInputField';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { CountryCodeAutoSuggest } from './CountryCodeAutoSuggest';
import {
    endDateIsSameOrAfterStartDateOrNull,
    isAssetIdentificationValid,
    isAssetNameValid,
    isVinValid,
} from './assetDetailsHelper';
import DatePicker from '@rio-cloud/rio-uikit/DatePicker';
import Select, { SelectOption } from '@rio-cloud/rio-uikit/Select';
import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import Switch from '@rio-cloud/rio-uikit/Switch';
import { AssetStatus, FormInputFieldType, FuelType, OwnershipType } from '../types';
import { Moment } from 'moment';
import classNames from 'classnames';
import { useLazyGetBrandQuery } from '../../../../services/assetsApi';
import { FeatureToggles, useToggle } from '../../../../../configuration/featureToggle/toggleHooks';

export const DATE_FORMAT = 'YYYY-MM-DD';
const NUMBER_OF_AXES_MAX_VALUE = 100;
const NUMBER_OF_AXES_MIN_VALUE = 0;

export const AssetDetails = (props: AssetDetailsProperties) => {
    const {
        asset,
        currentAssetIdentification,
        currentAssetBrand,
        currentAssetLicensePlate,
        currentAssetLicensePlateCountryCode,
        currentAssetName,
        currentAssetStatus,
        currentMasterdataAdr,
        currentMasterdataAirFreightSuitability,
        currentMasterdataComment,
        currentMasterdataCustomsBond,
        currentMasterdataCostCenter,
        currentMasterdataFuelType,
        currentMasterdataLeasingRate,
        currentMasterdataNumberOfAxes,
        currentMasterdataOwnershipEndDate,
        currentMasterdataOwnershipEndDateChangeJustTriggered,
        currentMasterdataOwnershipStartDate,
        currentMasterdataOwnershipTermInMonths,
        currentMasterdataOwnershipTermInMonthsChangeJustTriggered,
        currentMasterdataOwnershipType,
        currentMasterdataReference,
        currentMasterdataRegisteredFirstAt,
        currentMasterdataTires,
        currentMasterdataVehicleModel,
        currentMasterdataEngineType,
        identificationHasError,
        locale,
        onAssetIdentificationChange,
        onAssetBrandChange,
        onAssetLicensePlateChange,
        onAssetLicensePlateCountryCodeChange,
        onAssetNameChange,
        onAssetStatusChange,
        onMasterdataAdr,
        onMasterdataAirFreightSuitability,
        onMasterdataComment,
        onMasterdataCustomsBond,
        onMasterdataCostCenter,
        onMasterdataFuelType,
        onMasterdataLeasingRate,
        onMasterdataNumberOfAxes,
        onMasterdataOwnershipType,
        onMasterdataOwnershipEndDate,
        onMasterdataOwnershipStartDate,
        onMasterdataReference,
        onMasterdataRegisteredFirstAt,
        onMasterdataOwnershipTermInMonths,
        onMasterdataTires,
        onMasterdataEngineType,
        onMasterdataVehicleModel,
        setIdentificationHasError,
    } = props;

    const intl = useIntl();

    const [triggerGetBrand, brandResult] = useLazyGetBrandQuery();
    const { value: featureEngineTypeVehicleModelIsEnabled } = useToggle(
        FeatureToggles.MASTERDATA_ENGINE_VEHICLE,
        false
    );
    const onAssetNameValueChange = (value: string | null) => {
        onAssetNameChange(value ?? '');
    };

    const onAssetLicensePlateValueChange = (value: string | null) => {
        onAssetLicensePlateChange(value);
    };

    const onIdentificationChange = (vinValue: string | null) => {
        const upperCaseValue = vinValue?.toUpperCase() ?? null;
        setIdentificationHasError(!isAssetIdentificationValid(upperCaseValue));
        onAssetIdentificationChange(upperCaseValue);
        if (upperCaseValue && isVinValid(upperCaseValue)) {
            triggerGetBrand({ vin: upperCaseValue });
        } else if (upperCaseValue && !isVinValid(upperCaseValue)) {
            onAssetBrandChange(null);
        }
    };

    useEffect(() => {
        if (brandResult.isSuccess && brandResult.currentData) {
            onAssetBrandChange(brandResult.currentData);
        } else if (brandResult.isLoading || brandResult.isError) {
            onAssetBrandChange(null);
        }
    }, [brandResult, onAssetBrandChange]);

    const isFuelTypeEditionDisabled = (): boolean => {
        return currentAssetBrand === 'MAN' && hasCm4Device();
    };

    const hasCm4Device = () =>
        props.associatedDevices[asset.id]?.some((association) => association.device.type === DeviceType.CM4);

    return (
        <form data-cy="detail-view-form">
            <FormInputField
                translationId={'assets.assets.asset.name'}
                currentValue={currentAssetName ?? ''}
                disabled={false}
                onValueChange={onAssetNameValueChange}
                dataAttribute="detail-view-form-name"
                withCopyButton={true}
                maxLength={100}
                hasError={!isAssetNameValid(currentAssetName)}
            />
            <FormInputField
                translationId={'assets.assets.asset.license-plate'}
                currentValue={currentAssetLicensePlate ?? ''}
                disabled={false}
                onValueChange={onAssetLicensePlateValueChange}
                dataAttribute="detail-view-form-license-plate"
                withCopyButton={true}
                maxLength={100}
            />
            <CountryCodeAutoSuggest
                assetId={asset.id}
                licensePlateCountryCode={currentAssetLicensePlateCountryCode}
                onSelectionChange={onAssetLicensePlateCountryCodeChange}
                locale={locale}
                hasError={false}
            />
            <FormInputField
                translationId={'assets.assets.asset.identification_vin'}
                currentValue={currentAssetIdentification ?? ''}
                hasError={identificationHasError}
                hasFeedback={identificationHasError}
                disabled={!!asset.identification}
                onValueChange={onIdentificationChange}
                dataAttribute="detail-view-form-identification"
                hasLengthValidation={true}
                maxLength={asset.identification ? undefined : 17}
                withCopyButton={!!asset.identification}
            />
            <FormInputField
                translationId={'assets.assets.asset.type'}
                currentValue={intl.formatMessage({ id: `assets.assets.asset.type.${asset.type.toLowerCase()}` })}
                disabled={true}
                dataAttribute="detail-view-form-type"
            />
            <FormInputField
                translationId={'assets.assets.asset.brand'}
                currentValue={currentAssetBrand ?? ''}
                disabled={true}
                dataAttribute="detail-view-form-brand"
            />
            {asset.masterdata?.plug_and_charge_ids?.map((pcId, index, pcIds) => (
                <FormInputField
                    translationId={'assets.assets.asset.pcids'}
                    messageValues={{ index: pcIds.length > 1 ? `${index + 1}. ` : '' }}
                    currentValue={pcId.pc_id}
                    key={pcId.pc_id}
                    disabled={true}
                    dataAttribute={'detail-view-form-pcid'}
                    withCopyButton={true}
                />
            ))}
            {
                <>
                    <FormInputField
                        translationId={'assets.masterdata.adr'}
                        currentValue={currentMasterdataAdr ?? ''}
                        disabled={false}
                        onValueChange={(it) => {
                            onMasterdataAdr(it);
                        }}
                        dataAttribute="detail-view-form-adr"
                        withCopyButton={false}
                        maxLength={100}
                        hasError={false}
                    />
                    <FormInputField
                        translationId={'assets.masterdata.customs-bond'}
                        currentValue={currentMasterdataCustomsBond ?? ''}
                        disabled={false}
                        onValueChange={(it) => {
                            onMasterdataCustomsBond(it);
                        }}
                        dataAttribute="detail-view-form-customs-bond"
                        withCopyButton={false}
                        maxLength={100}
                        hasError={false}
                    />

                    <FormInputField
                        translationId={'assets.masterdata.cost-center'}
                        currentValue={currentMasterdataCostCenter ?? ''}
                        disabled={false}
                        onValueChange={onMasterdataCostCenter}
                        dataAttribute="detail-view-form-cost-center"
                        withCopyButton={false}
                        maxLength={100}
                        hasError={false}
                    />
                    <FormInputField
                        translationId={'assets.masterdata.suitability-for-air-freight'}
                        currentValue={currentMasterdataAirFreightSuitability ?? ''}
                        disabled={false}
                        onValueChange={(it) => {
                            onMasterdataAirFreightSuitability(it);
                        }}
                        dataAttribute="detail-view-form-air-freight"
                        withCopyButton={false}
                        maxLength={100}
                        hasError={false}
                    />
                    <SelectWrapper
                        formatMessageId={'assets.masterdata.fuel-type'}
                        onSelect={(it) => onMasterdataFuelType(FuelType[it.toUpperCase() as keyof typeof FuelType])}
                        onClear={() => onMasterdataFuelType(null)}
                        value={currentMasterdataFuelType}
                        disabled={isFuelTypeEditionDisabled()}
                        tooltipMessageId={'assets.masterdata.fuel-type.tooltip'}
                        options={Object.values(FuelType)}
                        intl={intl}
                    />
                    {featureEngineTypeVehicleModelIsEnabled && (
                        <>
                            <FormInputField
                                translationId={'assets.masterdata.vehicle-model'}
                                currentValue={currentMasterdataVehicleModel ?? ''}
                                disabled={false}
                                onValueChange={onMasterdataVehicleModel}
                                dataAttribute="detail-view-form-vehicle-model"
                                withCopyButton={false}
                                maxLength={100}
                                hasError={false}
                            />
                            <FormInputField
                                translationId={'assets.masterdata.engine-type'}
                                currentValue={currentMasterdataEngineType ?? ''}
                                disabled={false}
                                onValueChange={onMasterdataEngineType}
                                dataAttribute="detail-view-form-engine-type"
                                withCopyButton={false}
                                maxLength={100}
                                hasError={false}
                            />
                        </>
                    )}
                    <FormInputField
                        translationId={'assets.masterdata.tires'}
                        currentValue={currentMasterdataTires ?? ''}
                        disabled={false}
                        onValueChange={(it) => {
                            onMasterdataTires(it);
                        }}
                        dataAttribute="detail-view-form-tires"
                        withCopyButton={false}
                        maxLength={100}
                        hasError={false}
                        type={FormInputFieldType.TEXTAREA}
                    />
                    <FormInputField
                        translationId={'assets.masterdata.number-of-axes'}
                        currentValue={currentMasterdataNumberOfAxes?.toString() ?? ''}
                        disabled={false}
                        onValueChange={(it) => {
                            onMasterdataNumberOfAxes(
                                limitValue(getNumberOrNull(it), NUMBER_OF_AXES_MIN_VALUE, NUMBER_OF_AXES_MAX_VALUE)
                            );
                        }}
                        dataAttribute="detail-view-form-axes"
                        withCopyButton={false}
                        maxLength={100}
                        hasError={false}
                    />
                    <FormInputField
                        translationId={'assets.masterdata.comment'}
                        currentValue={currentMasterdataComment ?? ''}
                        disabled={false}
                        onValueChange={(it) => {
                            onMasterdataComment(it);
                        }}
                        dataAttribute="detail-view-form-comment"
                        withCopyButton={false}
                        maxLength={100}
                        hasError={false}
                        type={FormInputFieldType.TEXTAREA}
                    />
                    <div
                        style={{
                            border: 1,
                            width: '100%',
                            borderStyle: 'solid',
                            padding: '1em',
                            borderColor: '#a7afbb',
                        }}
                    >
                        <div className={'form-group'}>
                            <label>
                                <FormattedMessage id={'assets.masterdata.date-of-first-registration'} />
                            </label>
                            <DatePickerWrapper
                                value={currentMasterdataRegisteredFirstAt}
                                onChange={onMasterdataRegisteredFirstAt}
                            />
                        </div>
                        <div className={'form-group'}>
                            <label>
                                <FormattedMessage id={'assets.masterdata.ownership-start-date'} />
                            </label>
                            <DatePickerWrapper
                                value={currentMasterdataOwnershipStartDate}
                                onChange={onMasterdataOwnershipStartDate}
                            />
                        </div>
                        <div
                            className={`form-group ${
                                currentMasterdataOwnershipEndDateChangeJustTriggered ? 'has-success' : ''
                            }`}
                        >
                            <label>
                                <FormattedMessage id={'assets.masterdata.ownership-end-date'} />
                            </label>
                            <DatePickerWrapper
                                value={currentMasterdataOwnershipEndDate}
                                onChange={onMasterdataOwnershipEndDate}
                                hasError={
                                    !endDateIsSameOrAfterStartDateOrNull(
                                        currentMasterdataOwnershipStartDate,
                                        currentMasterdataOwnershipEndDate
                                    )
                                }
                                errorFormattedMessageId={
                                    'assets.masterdata.select-date.error.end-date-before-start-date'
                                }
                            />
                        </div>
                        <div className={currentMasterdataOwnershipTermInMonthsChangeJustTriggered ? 'has-success' : ''}>
                            <FormInputField
                                translationId={'assets.masterdata.term-in-months'}
                                currentValue={currentMasterdataOwnershipTermInMonths?.toString() ?? ''}
                                disabled={false}
                                onValueChange={(it) => {
                                    onMasterdataOwnershipTermInMonths(getNumberOrNull(it));
                                }}
                                dataAttribute="detail-view-form-term"
                                withCopyButton={false}
                                maxLength={100}
                                hasError={false}
                            />
                        </div>
                        <SelectWrapper
                            formatMessageId={'assets.masterdata.ownership-type'}
                            onSelect={(it) =>
                                onMasterdataOwnershipType(
                                    OwnershipType[it?.toUpperCase() as keyof typeof OwnershipType]
                                )
                            }
                            onClear={() => onMasterdataOwnershipType(null)}
                            value={currentMasterdataOwnershipType}
                            options={Object.values(OwnershipType)}
                            intl={intl}
                        />
                        <FormInputField
                            translationId={'assets.masterdata.reference'}
                            currentValue={currentMasterdataReference ?? ''}
                            disabled={false}
                            onValueChange={(it) => {
                                onMasterdataReference(it);
                            }}
                            dataAttribute="detail-view-form-reference"
                            withCopyButton={false}
                            maxLength={100}
                            hasError={false}
                        />
                        <FormInputField
                            translationId={'assets.masterdata.leasing-rate'}
                            currentValue={currentMasterdataLeasingRate?.toString() ?? ''}
                            disabled={false}
                            onValueChange={(it) => {
                                onMasterdataLeasingRate(getNumberOrNull(it));
                            }}
                            dataAttribute="detail-view-form-leasing-rate"
                            withCopyButton={false}
                            maxLength={100}
                            hasError={false}
                        />
                    </div>
                </>
            }
            <span className={'margin-right-10'}>
                <label className={'control-label'}>
                    <FormattedMessage id={'assets.assets.asset.status'} />
                </label>
                <Switch
                    checked={currentAssetStatus === AssetStatus.active}
                    keyName={'asset-status-switch'}
                    onChange={onAssetStatusChange}
                    minWidth={50}
                    enabledText={intl.formatMessage({ id: 'assets.assets.asset.status.active' })}
                    disabledText={intl.formatMessage({ id: 'assets.assets.asset.status.archived' })}
                />
            </span>
        </form>
    );
};

function SelectWrapper(props: {
    formatMessageId: string;
    onSelect: (value: string) => void;
    onClear: () => void;
    value: string | null | undefined;
    options: string[];
    intl: IntlShape;
    tooltipMessageId?: string | undefined;
    disabled?: boolean;
}) {
    const select = (
        <Select
            id={props.formatMessageId}
            disabled={props.disabled}
            className={'form-group'}
            value={[props.value || '']}
            placeholder={props.intl.formatMessage({ id: props.formatMessageId })}
            options={props.options.map((it) => ({
                id: it,
                label: props.intl.formatMessage({ id: `${props.formatMessageId}.${it}` }),
            }))}
            onChange={(selectedOption: SelectOption | undefined) => {
                if (selectedOption) {
                    props.onSelect(selectedOption.id);
                } else {
                    props.onClear();
                }
            }}
            useClear={true}
        />
    );

    return (
        <div>
            <label htmlFor={props.formatMessageId}>
                <FormattedMessage id={props.formatMessageId} />
            </label>
            {props.disabled && props.tooltipMessageId ? (
                <SimpleTooltip
                    content={
                        <FormattedMessage
                            id={props.tooltipMessageId}
                            values={{
                                man: 'MAN',
                            }}
                        />
                    }
                    placement={'left'}
                >
                    <div>{select}</div>
                </SimpleTooltip>
            ) : (
                select
            )}
        </div>
    );
}

const DatePickerWrapper = (
    props: React.PropsWithChildren<{
        onChange: (value: string | undefined) => void;
        value: string | undefined;
        hasError?: boolean;
        errorFormattedMessageId?: string;
    }>
) => {
    const intl = useIntl();
    return (
        <>
            <DatePicker
                onChange={(value: string | Moment | undefined) => {
                    if (value && typeof value === 'object') {
                        props.onChange(value.format(DATE_FORMAT));
                    } else {
                        props.onChange(undefined);
                    }
                }}
                value={props.value}
                closeOnSelect={true}
                dateFormat={'YYYY-MM-DD'}
                inputProps={{
                    value: props.value === null || props.value === undefined ? '' : props.value,
                    placeholder: intl.formatMessage({ id: 'assets.masterdata.select-date' }),
                }}
                timeFormat={false}
                clearableInput={true}
                className={classNames(props.hasError ? 'has-error' : '')}
                mandatory={false}
            />
            {props.hasError === true && (
                <p className={classNames('text-danger', 'text-size-12')}>
                    <FormattedMessage id={props.errorFormattedMessageId} />
                </p>
            )}
        </>
    );
};

const limitValue = (value: number | null, minValue: number, maxValue: number) => {
    return value ? Math.max(Math.min(value, maxValue), minValue) : null;
};

const getNumberOrNull = (it: string | null): number | null => {
    const parsedInt = parseInt(it ?? '', 10);
    return isNaN(parsedInt) ? null : parsedInt;
};

AssetDetails.headingText = <FormattedMessage id={'assets.assets.asset.basic-asset-data'} />;
