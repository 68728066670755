import { AssetAssociation, DeviceType, SelectedTag } from '../../../components/assets/details/types';
import { Asset, AssociatedDevice, Masterdata, Tag } from '../../../components/assets/types';
import { assetDeleteDialogClose, assetDeleteDialogOpen } from '../../../reducers/assets/assetsSlice';

export enum AssetsListDetailTab {
    ASSET_DETAILS = 'details',
    TAG_LIST = 'group',
    DEVICE_LIST = 'device',
}

export interface AssetListDetailOwnProperties {
    asset: Asset;
    onClose: () => void;
    toggleConfirmDialog: (opened: boolean, newRowId: string | null, discardChangesCallback: () => void) => void;
    discardChangesCallbackToCloseSidebar: () => void;
    toggleUnsavedAssetChanges: (unsavedChanges: boolean) => void;
}

export interface AssetListDetailPropertiesFromState {
    associatedDevices: { [key: string]: AssociatedDevice[] };
    assetUpdating: boolean;
    assetDeleting: boolean;
    displayDeleteDialog: boolean;
    deletionSuccessful: boolean;
    deletionErrorMessageCode: string | null;
    updateSuccessful: boolean;
    updateFailed: boolean;
    updateErrorCode: string | null;
    availableTags: Tag[];
    assetTagsUpdating: boolean;
    currentAssetDetailsTab: AssetsListDetailTab;
}

export interface AssetListDetailPropsFromDispatch {
    fetchSelectedDevices: (assetId: string, associatedDevices: { [key: string]: AssociatedDevice[] }) => Promise<void>;
    updateAssetTags: (asset: Asset, tags: SelectedTag[]) => Promise<void>;
    openDeleteDialog: () => { type: typeof assetDeleteDialogOpen.type };
    closeDeleteDialog: () => { type: typeof assetDeleteDialogClose.type };
    deleteAsset: (asset: Asset) => Promise<void>;
    updateAsset: (originalAsset: Asset, updatedAsset: Asset) => Promise<void>;
    updateMasterdata: (
        asset: Asset,
        originalMasterdata: Masterdata | null,
        updatedMasterdata: Masterdata
    ) => Promise<void>;
    createAssetTags: (assetTags: SelectedTag[], assetToUpdate?: Asset) => Promise<void>;
    changeAssetDetailsTab: (selectedTab: AssetsListDetailTab) => void;
    resetAssetUpdateState: () => void;
    resetAssetDeletionState: () => void;
}

export interface DeviceListPropertiesFromDispatch {
    deleteAssociation: (association: AssetAssociation, deviceType: DeviceType) => Promise<void>;
    openEasyOnboardingDialog: (selectedAssetId: string) => void;
}

export interface DeviceListPropertiesFromState {
    asset: Asset | null;
    fetchingDevices: boolean;
    associatedDevices: AssociatedDevice[];
}

export interface TagManagerWrapperOwnProperties {
    accountId: string | undefined | null;
    assetTags: string[] | undefined;
    onTagListChange: (selectedTags: SelectedTag[]) => void;
    availableTags: Tag[];
    useCustomTags: boolean;
    customMessage: string;
}

export interface TagManagerWrapperPropsFromState {
    taggingServiceIsAvailable: boolean;
}
