import { combineReducers, configureStore } from '@reduxjs/toolkit';
import loginReducer from '../login/loginSlice';
import appReducer from '../../features/app/reducers/appSlice';
import tokenHandlingReducer from '../tokenHandling/tokenHandlingSlice';
import assetsReducer from '../../features/app/reducers/assets/assetsSlice';
import tagsReducer from '../../features/app/reducers/tags/tagsSlice';
import easyOnboardingSurveyReducer from '../../features/app/reducers/easyOnboardingSurvey/easyOnboardingSlice';
import { assetsApi } from '../../features/services/assetsApi';
import { contractsApi } from '../../features/services/contractsApi';
import langReducer from '../lang/langSlice';

export const rootReducer = combineReducers({
    lang: langReducer,
    app: appReducer,
    login: loginReducer,
    tokenHandling: tokenHandlingReducer,
    assets: assetsReducer,
    easyOnboardingSurvey: easyOnboardingSurveyReducer,
    tags: tagsReducer,
    [assetsApi.reducerPath]: assetsApi.reducer,
    [contractsApi.reducerPath]: contractsApi.reducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) =>
    configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(assetsApi.middleware, contractsApi.middleware),
        preloadedState: preloadedState,
    });

export const store = setupStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];
