import classNames from 'classnames';
import { AssetStatus } from './types';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { FormattedMessage } from 'react-intl';

interface AssetStatusProps {
    assetStatus: AssetStatus;
}

export const AssetStatusHint = (props: AssetStatusProps) => {
    const { assetStatus } = props;

    const statusToolTip = <FormattedMessage id={`assets.assets.asset.status.${assetStatus}`} />;

    const labelClassNames = classNames(
        'rioglyph',
        'rioglyph-sphere',
        assetStatus === AssetStatus.active && 'text-color-primary',
        assetStatus === AssetStatus.archived && 'text-color-light'
    );

    return (
        <OverlayTrigger
            placement={'right'}
            overlay={
                <Tooltip id="tooltip" className="right-top" width="auto">
                    {statusToolTip}
                </Tooltip>
            }
        >
            <span className={labelClassNames} />
        </OverlayTrigger>
    );
};
