import map from 'lodash/map';
import filter from 'lodash/filter';
import { AssetsThunkDispatch, AssetThunkAction } from './types';
import {
    AssetAssociation,
    AssetAssociationWithDevice,
    DeviceType,
    SelectedTag,
    SelectedTagStatus,
} from '../../components/assets/details/types';
import { Asset, AssetStatus, AssetWithEmbed, AssociatedDevice } from '../../components/assets/types';
import { config } from '../../../../config';
import { getErrorCode, sleep } from '../helper';
import {
    closableSuccessFormattedNotification,
    nonVanishingFormattedErrorNotification,
} from '../../components/assets/ClickableNotifications';
import { getAccessToken } from '../../../../configuration/tokenHandling/tokenHandlingSlice';
import { AccessToken } from '../../../../configuration/types';
import { reportErrorToSentry } from '../../../../configuration/setup/sentry';
import {
    assetDeletionFailed,
    assetDeletionFinished,
    assetDeletionStarted,
    assetDeletionSuccessful,
    assetsLoaded,
    assetsLoadedFailed,
    assetsSelectedAssociatedDevicesChanged,
    assetsSelectedDevicesLoaded,
    assetsSelectedDevicesLoadedFailed,
    assetsStartLoading,
    assetsStartLoadingSelectedDevices,
    assetTableRowSelected,
    assetTagAddingFailed,
    assetTagAddingSuccessful,
    assetTagRemovingFailed,
    assetTagRemovingSuccessful,
    assetTagsUpdatingFinished,
    assetTagsUpdatingStarted,
    assetUpdatingFailed,
    assetUpdatingFinished,
    assetUpdatingStarted,
    assetUpdatingSuccessful,
    associationDeletionFailed,
    associationDeletionFinished,
    associationDeletionStarted,
    associationDeletionSuccessful,
    associationsLoaded,
    associationsLoadedFailed,
    associationsStartLoading,
    clearDeviceCache,
    selectedAssetUpdate,
} from '../../reducers/assets/assetsSlice';
import { RootState } from '../../../../configuration/setup/store';

// UTILS

const hasAssetChanges = (updatedAsset: Asset, originalAsset: Asset) => {
    return (
        updatedAsset.name !== originalAsset.name ||
        (originalAsset.license_plate
            ? updatedAsset.license_plate !== originalAsset.license_plate
            : updatedAsset.license_plate && updatedAsset.license_plate.length > 0) ||
        updatedAsset.license_plate_country_code !== originalAsset.license_plate_country_code ||
        updatedAsset.status !== originalAsset.status ||
        (originalAsset.identification ? false : updatedAsset.identification !== null && updatedAsset.brand !== null)
    );
};

const mapToAssets = (items: AssetWithEmbed[]): Asset[] =>
    items.map((assetWithEmbed: AssetWithEmbed) => ({
        id: assetWithEmbed.id,
        account_id: assetWithEmbed.account_id,
        name: assetWithEmbed.name,
        identification: assetWithEmbed.identification,
        identification_type: assetWithEmbed.identification_type,
        type: assetWithEmbed.type,
        status: assetWithEmbed.status,
        brand: assetWithEmbed.brand,
        license_plate: assetWithEmbed.license_plate,
        license_plate_country_code: assetWithEmbed.license_plate_country_code,
        tags: assetWithEmbed?._embedded?.tags?.items?.map((item: { id: string }) => item.id) ?? [],
        masterdata: assetWithEmbed?._embedded?.master_data ?? null,
    }));

// API CALLS

async function fetchAssetsPaginated(accessToken: AccessToken): Promise<Asset[]> {
    let nextLink: string | undefined = `${config.backend.assetAdmin}/assets?embed=(tags,master_data)`;
    let assets: Asset[] = [];

    while (nextLink !== undefined) {
        const response: any = await fetch(nextLink, { headers: { Authorization: `Bearer ${accessToken}` } });
        const contentType = response.headers.get('content-type');
        if (response.status === 200 && contentType && contentType.indexOf('application/json') !== -1) {
            const json = await response.json();
            nextLink = json._links?.next?.href;
            assets = assets.concat(mapToAssets(json.items));
        } else {
            throw new Error(response);
        }
    }
    return assets;
}

// THUNKS

export function fetchAssets(): AssetThunkAction<Promise<void>> {
    return async (dispatch: AssetsThunkDispatch, getState: () => RootState) => {
        const state = getState();
        dispatch(assetsStartLoading());

        await fetchAssetsPaginated(getAccessToken(state))
            .then((result: Asset[]) => dispatch(assetsLoaded(result)))
            .catch(() => dispatch(assetsLoadedFailed()));
    };
}

export function updateAsset(originalAsset: Asset, updatedAsset: Asset): AssetThunkAction<Promise<void>> {
    return async (dispatch: AssetsThunkDispatch, getState: () => RootState) => {
        const state = getState();
        if (hasAssetChanges(updatedAsset, originalAsset)) {
            dispatch(assetUpdatingStarted());
            const assetEndpoint = `${config.backend.assetAdmin}/assets/${updatedAsset.id}`;
            const updateAssetExternal: AssetWithEmbed = {
                id: updatedAsset.id,
                account_id: updatedAsset.account_id,
                name: updatedAsset.name,
                identification: updatedAsset.identification,
                identification_type: updatedAsset.identification_type,
                type: updatedAsset.type,
                status: updatedAsset.status,
                brand: updatedAsset.brand,
                license_plate: updatedAsset.license_plate,
                license_plate_country_code: updatedAsset.license_plate_country_code,
            };
            const response = await fetch(assetEndpoint, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${getAccessToken(state)}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateAssetExternal),
            });
            if (response.status === 202) {
                dispatch(
                    selectedAssetUpdate({
                        assetId: updatedAsset.id,
                        name: updatedAsset.name,
                        license_plate: updatedAsset.license_plate,
                        license_plate_country_code: updatedAsset.license_plate_country_code,
                        status: updatedAsset.status,
                        brand: updatedAsset.brand,
                        identification_type: updatedAsset.identification_type,
                        identification: updatedAsset.identification,
                    })
                );
                if (originalAsset.status === AssetStatus.active && updatedAsset.status === AssetStatus.archived) {
                    setTimeout(() => dispatch(clearDeviceCache(updatedAsset.id)), 1000);
                }
                dispatch(assetTableRowSelected(updatedAsset));
                //
                dispatch(assetUpdatingSuccessful());
            } else {
                const errorCode = await getErrorCode(response);
                //
                dispatch(assetUpdatingFailed({ errorCode }));
            }
            dispatch(assetUpdatingFinished());
        }
    };
}

export function deleteAsset(asset: Asset): AssetThunkAction<Promise<void>> {
    return async (dispatch: AssetsThunkDispatch, getState: () => RootState) => {
        const state = getState();
        dispatch(assetDeletionStarted());
        const assetEndpoint = `${config.backend.assetAdmin}/assets/${asset.id}`;
        const response = await fetch(assetEndpoint, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${getAccessToken(state)}`,
            },
        });
        if (response.status === 202) {
            //
            dispatch(
                assetDeletionSuccessful({
                    assetId: asset.id,
                })
            );
        } else {
            //
            dispatch(assetDeletionFailed());
        }
        dispatch(assetDeletionFinished());
    };
}

export function fetchDevices(): AssetThunkAction<Promise<void>> {
    return async (dispatch: AssetsThunkDispatch, getState: () => RootState) => {
        const state = getState();
        dispatch(associationsStartLoading());

        await fetchDevicesPaginated(getAccessToken(state))
            .then((result: AssetAssociationWithDevice[]) => dispatch(associationsLoaded(result)))
            .catch(() => dispatch(associationsLoadedFailed()));
    };
}

const fetchDevicesPaginated = async (accessToken: AccessToken): Promise<AssetAssociationWithDevice[]> => {
    let nextLink: string | null | undefined = `${config.backend.assetAdmin}/associations?embed=(device)`;
    let associationsWithDevices: AssetAssociationWithDevice[] = [];

    while (nextLink !== null && nextLink !== undefined) {
        const response: any = await fetch(nextLink, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        const contentType = response.headers.get('content-type');

        if (response.status === 200 && contentType && contentType.indexOf('application/json') !== -1) {
            const json = await response.json();
            nextLink = json._links?.next?.href;
            associationsWithDevices = associationsWithDevices.concat(json.items);
        } else {
            throw new Error('Error when fetching devices. Status code:', response.status);
        }
    }

    return associationsWithDevices;
};

export function fetchSelectedDevices(
    assetId: string,
    associatedDevices: { [p: string]: AssociatedDevice[] }
): (dispatch: AssetsThunkDispatch, getState: () => RootState) => Promise<void> {
    return async (dispatch: AssetsThunkDispatch, getState: () => RootState) => {
        const state = getState();
        // eslint-disable-next-line no-prototype-builtins
        if (associatedDevices.hasOwnProperty(assetId)) {
            // TODO: split selection of devices in separate action
            dispatch(
                assetsSelectedAssociatedDevicesChanged({
                    selectedAssociatedDevices: associatedDevices[assetId],
                })
            );
        } else {
            dispatch(assetsStartLoadingSelectedDevices());
            const url = new URL(`${config.backend.assetAdmin}/associations`);
            url.search = new URLSearchParams({ asset_id: assetId }).toString();
            const rawUrlWithEmbedParameter = `${url.toString()}&embed=(device)`;

            const response = await fetch(rawUrlWithEmbedParameter, {
                headers: {
                    Authorization: `Bearer ${getAccessToken(state)}`,
                },
            });
            const contentType = response.headers.get('content-type');

            if (response.status === 200 && contentType && contentType.indexOf('application/json') !== -1) {
                const json = await response.json();
                const filteredResults: AssetAssociationWithDevice[] = filter(
                    json.items,
                    (association: AssetAssociationWithDevice) => association._embedded !== undefined
                );
                const connectedDevices: AssociatedDevice[] = map(
                    filteredResults,
                    (association: AssetAssociationWithDevice) => {
                        return {
                            device: association._embedded.device,
                            associationId: association.id,
                        };
                    }
                );
                dispatch(
                    assetsSelectedDevicesLoaded({
                        assetId,
                        associatedDevices: connectedDevices,
                    })
                );
            } else {
                dispatch(assetsSelectedDevicesLoadedFailed());
            }
        }
    };
}

export function deleteAssociation(
    association: AssetAssociation,
    deviceType: DeviceType
): AssetThunkAction<Promise<void>> {
    return async (dispatch: AssetsThunkDispatch, getState: () => RootState) => {
        const state = getState();
        dispatch(associationDeletionStarted());
        const associationEndpoint = `${config.backend.assetAdmin}/associations/${association.id}`;
        const response = await fetch(associationEndpoint, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${getAccessToken(state)}`,
            },
        });
        if (response.status === 202) {
            dispatch(
                associationDeletionSuccessful({
                    associationId: association.id,
                })
            );
            closableSuccessFormattedNotification('assets.assets.delete.success');
        } else {
            dispatch(associationDeletionFailed());
            const errorCode = await getErrorCode(response);
            nonVanishingFormattedErrorNotification(
                'assets.assets.asset.delete-confirmation-dialog.content.error.DEFAULT',
                `${errorCode}`
            );
        }
        dispatch(associationDeletionFinished());
    };
}

export function updateAssetTags(asset: Asset, tags: SelectedTag[]): AssetThunkAction<Promise<void>> {
    const assetTagsBaseEndpoint = `${config.backend.assetAdmin}/assets/${asset.id}/tags`;

    async function putRequest(tagToAdd: SelectedTag, accessToken: AccessToken) {
        const assetTagsEndpoint = `${assetTagsBaseEndpoint}/${tagToAdd.id}`;
        return fetch(assetTagsEndpoint, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: tagToAdd.id,
            }),
        });
    }

    async function deleteRequest(tagToRemove: SelectedTag, accessToken: AccessToken) {
        const assetTagsEndpoint = `${assetTagsBaseEndpoint}/${tagToRemove.id}`;
        return fetch(assetTagsEndpoint, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        });
    }

    return async (dispatch: AssetsThunkDispatch, getState: () => RootState) => {
        const state = getState();
        dispatch(assetTagsUpdatingStarted());
        const tagsToRemove = tags.filter((tag) => tag.status === SelectedTagStatus.removed);
        const tagsToAdd = tags.filter((tag) => tag.status === SelectedTagStatus.added);
        const createdTagsToAdd = tags.filter((tag) => tag.status === SelectedTagStatus.created);

        for (const tagToRemove of tagsToRemove) {
            const response = await deleteRequest(tagToRemove, getAccessToken(state));
            if (response.status === 202) {
                dispatch(
                    assetTagRemovingSuccessful({
                        tag: tagToRemove,
                        asset,
                    })
                );
            } else {
                dispatch(
                    assetTagRemovingFailed({
                        tag: tagToRemove,
                        asset,
                    })
                );
            }
        }

        for (const tagToAdd of tagsToAdd) {
            const response = await putRequest(tagToAdd, getAccessToken(state));
            if (response.status === 202) {
                dispatch(
                    assetTagAddingSuccessful({
                        tag: tagToAdd,
                        asset,
                    })
                );
            } else {
                dispatch(
                    assetTagAddingFailed({
                        tag: tagToAdd,
                        asset,
                    })
                );
            }
        }

        for (const createdTagToAdd of createdTagsToAdd) {
            for (let retryCount = 0; retryCount < config.actions.assets.maximumCreateTagRetries; retryCount++) {
                const response = await putRequest(createdTagToAdd, getAccessToken(state));
                if (response.status === 202) {
                    //
                    dispatch(
                        assetTagAddingSuccessful({
                            tag: createdTagToAdd,
                            asset,
                        })
                    );
                    break;
                }
                await sleep(config.actions.assets.sleepBetweenCreateTagRetriesMs);
                if (retryCount === config.actions.assets.maximumCreateTagRetries - 1) {
                    reportErrorToSentry(
                        new Error(`Could not add tag ${createdTagToAdd.id} to asset ${asset.id}
                        after ${config.actions.assets.maximumCreateTagRetries} retries`)
                    );
                    //
                    dispatch(
                        assetTagAddingFailed({
                            tag: createdTagToAdd,
                            asset,
                        })
                    );
                }
            }
        }

        dispatch(assetTagsUpdatingFinished());
    };
}
